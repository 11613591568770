<template>
  <div class="bdc_main_wrap whero">
    <div class="thw_content">
      <div class="internal-sub-nav">
        <div class="isn-item active">Overview</div>
      </div>
    </div>
    <!-- Component for Summary Section -->
    <div class="bdc_top_panel_wrap">
      <quick-stats
        v-if="account.id !== 73"
        :field1="stat1.field1"
        :hide-field2="true"
        :show-change="showDiff"
        :loading="statLoading || filtersAppliedLoading"
      />
      <quick-stats
        :field1="stat2.field1"
        :hide-field2="true"
        :show-change="showDiff"
        :loading="statLoading || filtersAppliedLoading"
      />
      <quick-stats
        :field1="stat1.field2"
        :hide-field2="true"
        :show-change="showDiff"
        :loading="statLoading || filtersAppliedLoading"
      />
      <quick-stats
        :field1="stat2.field2"
        :hide-field2="true"
        :show-change="showDiff"
        :loading="statLoading || filtersAppliedLoading"
      />
    </div>
    <!-- Component for Campaigns/Ad group table -->
    <div id="performanceAnalysis"></div>
    <div class="bdc_panel full-width expan def-box" :class="isCampaignTableExpanded ? 'expd' : ''">
      <performance-analysis
        section-title="Performance"
        :tab-options="paTabOptions"
        :meta-data="paMetaData"
        :rows="paRows"
        :loading="paLoading || filtersAppliedLoading"
        :is-table-expanded="isCampaignTableExpanded"
        :sort-by="paSortBy"
        :sort-direction="paSortDirection"
        @on-tab-click="handlePATabClick"
        @on-sort-direction-change="handlePaSortDirectionChange"
        @on-change-sort-by="handleSortByChange"
        @on-table-expand="handleTableExpand"
        @on-export="handleExportClick"
      />
    </div>
    <!-- Component for Top Performer -->
    <div class="bdc_panel two-thirds last" style="height: 465px;">
      <top-performers
        :key="JSON.stringify(tpMetricOptions)"
        :dimension-options="tpDimensionOptions"
        :metric-options="tpMetricOptions"
        :rows="tpRows"
        :loading="tpLoading || filtersAppliedLoading"
        @on-options-change="handleTPOptionsChange"
        @on-export="handleExportClick"
      />
    </div>
    <!-- Component for Distribution -->
    <div class="bdc_panel one-thirds" style="height: 465px;">
      <distribution
        :dimension-options="distDimensionOptions"
        :metric-options="distMetricOptions"
        :rows="distRows"
        :loading="distLoading || filtersAppliedLoading"
        :dimension-options-obj="distDimensionOptions"
        @on-options-change="handleDistOptionsChange"
        @on-export="handleExportClick"
      />
    </div>
    <!-- Component for Daily view -->
    <div class="bdc_panel full-width" style="height: 510px;position: relative;">
      <daily-view
        :metric-options="dailyViewMetricOptions"
        :loading="dailyViewLoading || filtersAppliedLoading"
        :columns="dailyViewColumns"
        @on-options-change="handleDailyViewOptChange"
        @on-export="handleExportClick"
      />
    </div>
  </div>
</template>
<script>
import { get } from 'vuex-pathify';
import _ from 'underscore';
import moment from 'moment';
import { uuid } from '@/helpers/global/misc-helpers';
import { getCorrectLabels, getValueFormat, downloadCSVFile } from '@/util/utility-functions';

import { VIA_ACCOUNT_ID } from '@/constant';
import formatNumber, { formatNumberAsFullNumber } from '~/util/numeral';
import QuickStats from '~/components/quick-stats.vue';
import PerformanceAnalysis from '~/components/performance-analysis.vue';
import Distribution from '~/components/distribution.vue';
import TopPerformers from '~/components/top-performers.vue';
import DailyView from '~/components/daily-view.vue';
import {
  ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS,
  DISTRIBUTION_DIMENSION_OPTIONS,
  DISTRIBUTION_MEASUREMENT_OPTIONS,
  PERFORMANCE_ANALYSIS_DIMENSION_COLUMN,
  PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION,
  PERFORMANCE_ANALYSIS_DIMENSION_TITLES,
  TP_DIMENSION_OPTIONS,
  DAILY_VIEW_METRIC_OPTIONS,
  DELIVERY_METRIC_OPTIONS,
  ACCOUNT_SPECIFIC_ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS,
  ACCOUNT_SPECIFIC_DISTRIBUTION_DIMENSION_OPTIONS,
  ACCOUNT_SPECIFIC_PERFORMANCE_ANALYSIS_DIMENSION_TITLES,
} from '~/search-constant';
import appConsoleDataLayer from '~/api/app-console-data';
import { formatDateForAPI } from '~/util/apiDateFormat';

export default {
  components: {
    QuickStats,
    PerformanceAnalysis,
    Distribution,
    DailyView,
    TopPerformers,
  },
  props: {
    activeSolutionId: {
      type: Number,
      required: false,
      default: 1,
    },
    isCampaignTableExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const paTabOptions = ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS;
    let initialDimension = 'campaign';
    if (paTabOptions?.length) {
      const defaultSelectedTab = paTabOptions.find((t) => t.selected);
      initialDimension = defaultSelectedTab?.id || this.tabOptions[0].id;
    }
    const distDimensionOptions = DISTRIBUTION_DIMENSION_OPTIONS;
    if (initialDimension) {
      distDimensionOptions.forEach((opt) => {
        if (opt.children && opt.children.length) {
          opt.children = opt.children.map((child) => {
            if (initialDimension === child.id) {
              child.selected = true;
            } else {
              child.selected = false;
            }
            return child;
          });
          if (opt.children.filter((child) => child.selected).length > 0) {
            opt.selected = true;
          } else {
            opt.selected = false;
          }
        } else if (initialDimension === opt.id) {
          opt.selected = true;
        } else {
          opt.selected = false;
        }
      });
    }
    const distMetricOptions = DISTRIBUTION_MEASUREMENT_OPTIONS;
    const selectedDistMetric = distMetricOptions?.length ? distMetricOptions[0].id : '';
    const selectedDistPixelIds = [];
    const tpMetricOptions = DELIVERY_METRIC_OPTIONS;
    const selectedTPMetric = tpMetricOptions?.length ? tpMetricOptions[0].id : '';

    const dailyViewTooltipColumnsMap = {
      roas: {
        col1: { key: 'revenue', header: 'TOTAL REV', rawHeader: 'Revenue' },
        col2: { key: 'spend', header: 'TOTAL SPEND', rawHeader: 'Spend' },
        col3: { key: 'roas', header: 'ROAS', rawHeader: 'ROAS' },
      },
      ctr: {
        col1: { key: 'clicks', header: 'CLICKS', rawHeader: 'Clicks' },
        col2: { key: 'impressions', header: 'IMPRESSIONS', rawHeader: 'Impressions' },
        col3: { key: 'ctr', header: 'CTR', rawHeader: 'CTR' },
      },
    };

    return {
      tpDimensionOptions: TP_DIMENSION_OPTIONS,
      tpMetricOptions,
      selectedTPMetric,
      tpData: [],
      tpRows: [],
      tpLoading: false,
      statLoading: false,
      isInitialLoad: true,
      searchSummaryData: {},
      stat1: { field1: {}, field2: {} },
      stat2: { field1: {}, field2: {} },
      dailyViewMetricOptions: DAILY_VIEW_METRIC_OPTIONS,
      distDimensionOptions,
      // distMetricOptions,
      selectedDistDimension: initialDimension,
      selectedDistMetric,
      selectedDistPixelIds,
      distRows: [],
      dailyViewTooltipColumnsMap,
      selectedDailyViewMetrics: ['impressions'],
      prevSelectedDailyViewMetrics: [],
      promises: [],
      dailyViewColumns: [],
      distLoading: false,
      dailyViewLoading: false,
      paTabOptions,
      selectedPADimension: initialDimension,
      paRequestId: '',
      paMetaData: [],
      paRows: [],
      paSortBy: '',
      paSortDirection: 'asc',
      paLoading: false,
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    dates: get('dashboard/dates'),
    selectedCampaignIds: get('dashboard/GET_SELECTED_CAMPAIGN_IDS'),
    selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),
    showDiff: get('dashboard/dates@compareWith'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    pixelOptions: get('dashboard/filters@pixelOptions'),
    selectedKeywords: get('dashboard/GET_SELECTED_KEYWORDS'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    filtersApplied: get('dashboard/filtersApplied'),
    filtersAppliedLoading: get('dashboard/filtersAppliedLoading'),
    selectedDashboardTab: get('dashboard/selectedDashboardTab'),
    pixelDropdownOptions() {
      if (!this.pixelOptions) {
        return [];
      }

      if (this.pixelOptions.length === 1) {
        return [];
      }

      return this.pixelOptions;
    },
    distMetricOptions() {
      let options = JSON.parse(JSON.stringify(DISTRIBUTION_MEASUREMENT_OPTIONS));
      if (this.account.id === VIA_ACCOUNT_ID) {
        options = options.filter((option) => option.id !== 'spend');
      }
      const conversionOptions = options.filter((o) => ['conversions', 'revenue'].includes(o.id));
      if (conversionOptions && this.pixelDropdownOptions && this.pixelDropdownOptions.length > 0) {
        conversionOptions.forEach((o) => {
          o.children = [];
          this.pixelDropdownOptions.forEach((p) =>
            o.children.push({ value: p.value, id: p.key, selected: true })
          );
        });
      }

      return options;
    },
    advertiserId() {
      return this.advertiser ? this.advertiser.id : 0;
    },
    payload() {
      return {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds: this.selectedCampaignIds,
        adGroups: this.selectedAdGrpOptStr,
        startDate: formatDateForAPI(this.dates.startDate),
        endDate: formatDateForAPI(this.dates.endDate),
        keywords: this.selectedKeywords,
        creatives: this.selectedCreatives,
        pixelIds: this.selectedPixelIds,
      };
    },
  },
  watch: {
    advertiser: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          await this.loadAllData();
        }
      },
    },

    dates: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (!this.filtersAppliedLoading) {
            await this.loadAllData();
          }
        }
      },
    },
    filtersApplied: {
      deep: true,
      async handler(n) {
        if (n) {
          await this.loadAllData();
          this.$store.set('dashboard/filtersApplied', false);
          this.$store.set('dashboard/filtersAppliedLoading', false);
        }
      },
    },
    pixelOptions(newPixelOptions) {
      if (!newPixelOptions) {
        this.selectedDistPixelIds = [];
        return;
      }

      if (newPixelOptions.length === 1) {
        this.selectedDistPixelIds = [];
        return;
      }

      this.selectedDistPixelIds = newPixelOptions?.map((p) => p.key) || [];
    },
  },

  beforeMount() {
    this.distDimensionOptions = getCorrectLabels(
      DISTRIBUTION_DIMENSION_OPTIONS,
      ACCOUNT_SPECIFIC_DISTRIBUTION_DIMENSION_OPTIONS[this.account.id]
    );
    this.paTabOptions = getCorrectLabels(
      ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS,
      ACCOUNT_SPECIFIC_ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS[this.account.id]
    );
    if (this.account.id === VIA_ACCOUNT_ID)
      this.dailyViewMetricOptions = DAILY_VIEW_METRIC_OPTIONS.filter((item) => item.id !== 'spend');
  },
  async mounted() {
    if (this.account.id === VIA_ACCOUNT_ID) {
      this.tpMetricOptions = DELIVERY_METRIC_OPTIONS.filter((item) => item.id !== 'cpc');
    }
    this.$store.set('dashboard/selectedDashboardTab', 'adgroup');
    await this.loadAllData();
  },
  methods: {
    handleExportClick(fileName, csvData) {
      try {
        downloadCSVFile(csvData, fileName);
      } catch (err) {
        console.error(`error downloading overview ${fileName} file ->`, err);
        throw err;
      }
    },
    handleTableExpand(val) {
      this.$emit('on-expand-campaign-table', val);
    },
    getFormattedChangeValue(change, reverseColors = false) {
      let css = '';
      if (change === 0) {
        css = 'zero';
      } else {
        const arrowCss = change < 0 ? 'caret-down' : 'caret-up';
        const changeMultiplier = reverseColors ? -1 : 1;
        const changeMultiplierApplied = change * changeMultiplier;
        css = changeMultiplierApplied < 0 ? 'negative-color' : 'positive-color';
        css = `${css}-${arrowCss}`;
      }
      const changeValue = formatNumber(Math.abs(change), 'percentage');
      return `<span class='${css}'>${changeValue}</span>`;
    },
    // function is used to process the results for metric data for apex chart
    // library resPayload:  response payload from api call, payload : the request payload for api
    async processMeasurementResults(res, metric) {
      const days = [];
      const last7Days = [];
      const tooltip = [];
      const exportRaw = [];
      const metricOption = this.dailyViewMetricOptions.find((opt) => opt.id === metric);
      const colLabel = metricOption?.value || '-';
      const exportRawHeaders = ['Date', colLabel];
      res?.breakup?.forEach((br) => {
        days.push(br.key);
        last7Days.push(br.value?.currentValue);
        const tooltipRows = [];
        const exportRawRows = [];
        const currentDate = moment(br.key).format('MM/DD/YYYY');
        exportRawRows.push({
          data: exportRawHeaders,
        });
        tooltipRows.push({
          meta: { css: 'lctt-wrap-type1' },
          data: [currentDate, formatNumber(br.value?.currentValue, getValueFormat(metric))],
        });
        exportRawRows.push({
          data: [br.key, formatNumberAsFullNumber(br.value?.currentValue, getValueFormat(metric))],
        });
        tooltip.push(tooltipRows);
        exportRaw.push(exportRawRows);
      });
      return {
        currentDays: days,
        currentDaysData: last7Days,
        tooltip,
        exportRaw,
      };
    },
    async processMetricResults(res, metric) {
      const days = [];
      const last7Days = [];

      const col1Key = this.dailyViewTooltipColumnsMap[metric]?.col1?.key || 'spend';
      const col2Key = this.dailyViewTooltipColumnsMap[metric]?.col2?.key || 'impressions';
      const col1Header = this.dailyViewTooltipColumnsMap[metric]?.col1?.header || 'TOTAL SPEND';
      const col2Header = this.dailyViewTooltipColumnsMap[metric]?.col2?.header || 'TOTAL IMPS';
      const col1RawHeader = this.dailyViewTooltipColumnsMap[metric]?.col1?.rawHeader || 'Spend';
      const col2RawHeader =
        this.dailyViewTooltipColumnsMap[metric]?.col2?.rawHeader || 'Impressions';
      const col3Header = this.dailyViewTooltipColumnsMap[metric]?.col3?.header || '';
      const col3RawHeader = this.dailyViewTooltipColumnsMap[metric]?.col3?.rawHeader || '';
      const tooltip = [];
      const exportRaw = [];
      const tooltipHeaders = ['DATE', col1Header, col2Header, col3Header];
      const exportRawHeaders = ['Date', col1RawHeader, col2RawHeader, col3RawHeader];

      res.breakup.forEach((br) => {
        const col1ObjRef = br.value[col1Key];
        const col2ObjRef = br.value[col2Key];
        const valueObjRef = br.value[metric];

        days.push(br.key);
        last7Days.push(valueObjRef?.currentValue);
        const tooltipRows = [];
        const exportRawRows = [];
        const currentDate = moment(br.key).format('MM/DD/YYYY');

        tooltipRows.push({
          meta: { css: 'lctt-wrap-header lctt-wrap-header-custom' },
          data: tooltipHeaders,
        });
        exportRawRows.push({
          data: exportRawHeaders,
        });
        tooltipRows.push({
          meta: { css: 'lctt-wrap-type1 lctt-wrap-custom' },
          data: [
            currentDate,
            formatNumber(col1ObjRef?.currentValue, getValueFormat(col1Key)),
            formatNumber(col2ObjRef?.currentValue, getValueFormat(col2Key)),
            formatNumber(valueObjRef?.currentValue, getValueFormat(metric)),
          ],
        });
        exportRawRows.push({
          data: [
            br.key,
            formatNumberAsFullNumber(col1ObjRef?.currentValue, getValueFormat(col1Key)),
            formatNumberAsFullNumber(col2ObjRef?.currentValue, getValueFormat(col2Key)),
            formatNumberAsFullNumber(valueObjRef?.currentValue, getValueFormat(metric)),
          ],
        });
        tooltip.push(tooltipRows);
        exportRaw.push(exportRawRows);
      });
      return {
        currentDays: days,
        currentDaysData: last7Days,
        tooltip,
        exportRaw,
      };
    },
    convertToStatField(data, field, title, decimals, fixedDecimals = false) {
      const o = {};
      o.title = title;
      o.valueFormat = getValueFormat(field);
      if (decimals) {
        o.decimals = decimals;
        o.optionalDecimals = !fixedDecimals;
      }
      o.current = {};
      o.current.value = data[field]?.currentValue;
      o.current.isInfinity = data[field]?.isCurrentInfinity;
      o.difference = {};
      o.difference.value = data[field]?.difference;
      o.difference.isInfinity = data[field]?.isCurrentInfinity || data[field]?.isCompareInfinity;
      return o;
    },
    async loadAllData() {
      if (!this.advertiser) {
        return;
      }
      const apiFuncs = [
        this.loadQuickStatsData(),
        this.loadPerformanceData(),
        this.loadTopPerformerData(),
        this.loadDistributionData(),
        this.loadDailyViewData(),
      ];
      await Promise.all(apiFuncs);
      this.$store.set('dashboard/filtersAppliedLoading', false);
    },
    extractImpressionsSummary(data) {
      const field1 = this.convertToStatField(data, 'spend', 'Spend', '00', true);
      const field2 = this.convertToStatField(data, 'impressions', 'Impressions', '0');
      return { field1, field2 };
    },
    extractClicksSummary(data) {
      const field1 = this.convertToStatField(data, 'clicks', 'Clicks', '00', false);
      const field2 = this.convertToStatField(data, 'ctr', 'CTR', '0');
      return { field1, field2 };
    },
    calculateCPA(spend, conversions) {
      if (!conversions || conversions === 0) {
        return 'NA';
      }
      return spend / conversions;
    },
    extractConversionsSummary(data, pixels) {
      const totalConversions = {
        currentValue: 0,
        difference: 0,
        isChangeInfinity: false,
      };

      // Collect data for all selected pixels
      pixels
        .filter((p) => p.checked)
        .forEach((p) => {
          const dataKey = `pixel_${p.key}_conversions`;
          totalConversions.currentValue += data[dataKey]?.currentValue || 0;
          totalConversions.difference += data[dataKey]?.difference || 0;
        });

      let { spend } = data;
      if (!spend) {
        spend = { currentValue: 0, difference: 0 };
      }
      totalConversions.compareValue = totalConversions.currentValue - totalConversions.difference;
      spend.compareValue = spend.currentValue - spend.difference;

      const currentCPA = this.calculateCPA(spend.currentValue, totalConversions.currentValue);
      const compareCPA = this.calculateCPA(spend.compareValue, totalConversions.compareValue);

      const totalCpa = {
        currentValue: currentCPA,
        compareValue: compareCPA,
        difference: currentCPA === 'NA' || compareCPA === 'NA' ? 'NA' : currentCPA - compareCPA,
        isChangeInfinity: false,
      };

      data.conversions = totalConversions;
      data.cpa = totalCpa;

      const field1 = this.convertToStatField(data, 'conversions', 'Conversions', '0');
      const field2 = this.convertToStatField(data, 'cpa', 'CPA', '0');
      return { field1, field2 };
    },
    calculateROAS(revenue, spend) {
      if (!spend || spend === 0) {
        return 'NA';
      }
      return (revenue / spend) * 100;
    },
    extractRevenueSummary(data, pixels) {
      const totalRevenue = {
        currentValue: 0,
        difference: 0,
        isChangeInfinity: false,
      };

      // Collect data for all selected pixels
      pixels
        .filter((p) => p.checked)
        .forEach((p) => {
          const dataKey = `pixel_${p.key}_revenue`;
          totalRevenue.currentValue += data[dataKey]?.currentValue || 0;
          totalRevenue.difference += data[dataKey]?.difference || 0;
        });

      let { spend } = data;
      if (!spend) {
        spend = { currentValue: 0, difference: 0 };
      }
      totalRevenue.compareValue = totalRevenue.currentValue - totalRevenue.difference;
      spend.compareValue = spend.currentValue - spend.difference;

      const currentROAS = this.calculateROAS(totalRevenue.currentValue, spend.currentValue);
      const compareROAS = this.calculateROAS(totalRevenue.compareValue, spend.compareValue);

      const totalRoas = {
        currentValue: currentROAS,
        compareValue: compareROAS,
        difference: currentROAS === 'NA' || compareROAS === 'NA' ? 'NA' : currentROAS - compareROAS,
        isChangeInfinity: false,
      };

      data.revenue = totalRevenue;
      data.roas = totalRoas;

      const field1 = this.convertToStatField(data, 'revenue', 'Revenue', '0');
      const field2 = this.convertToStatField(data, 'roas', 'ROAS', '0');
      return { field1, field2 };
    },
    async loadQuickStatsData() {
      this.statLoading = true;
      const copyOfPayload = { ...this.payload };
      if (this.dates.compareWith) {
        copyOfPayload.compareStartDate = formatDateForAPI(this.dates.compareStartDate);
        copyOfPayload.compareEndDate = formatDateForAPI(this.dates.compareEndDate);
      }
      this.searchSummaryData = await appConsoleDataLayer.fetchSearchSummary(
        this.advertiserId,
        this.activeSolutionId,
        copyOfPayload
      );

      this.stat1 = this.extractImpressionsSummary(this.searchSummaryData);
      this.stat2 = this.extractClicksSummary(this.searchSummaryData);
      this.statLoading = false;
    },
    async loadTopPerformerData() {
      this.tpLoading = true;
      const dimension = [];
      const keywordsOption = [];
      this.tpDimensionOptions = TP_DIMENSION_OPTIONS;
      this.tpDimensionOptions.forEach((o) => {
        if (this.payload.keywords.length > 0) {
          if (o.value === 'Keywords') {
            keywordsOption.push(o);
          }
        } else if (this.payload.creatives.length > 0) {
          if (o.value === 'Ads') {
            keywordsOption.push(o);
          }
        } else {
          keywordsOption.push(o);
        }
      });
      keywordsOption.forEach((o) => {
        if (o.children && o.children.length) {
          o.children.map((child) => dimension.push(child.id));
        } else {
          dimension.push(o.id);
        }
      });
      this.tpDimensionOptions = keywordsOption;
      const copyOfPayload = { ...this.payload, dimension };
      this.tpData = await appConsoleDataLayer.fetchSearchTopPerformerData(
        this.advertiserId,
        this.activeSolutionId,
        copyOfPayload
      );
      this.tpLoading = false;
      const defaultSelectedTPMetric = 'ctr';

      if (this.tpMetricOptions?.length) {
        this.tpMetricOptions.forEach((o) => {
          o.selected = o.id === defaultSelectedTPMetric;
        });
        const selectedMetricOption = this.tpMetricOptions.find((o) => o.selected);
        this.selectedTPMetric = selectedMetricOption?.id || this.tpMetricOptions[0].id;
      }
      this.prepareTopPerformerData([], this.selectedTPMetric);
    },
    async loadDistributionData() {
      this.distLoading = true;
      const keywordsOption = [];
      // this.distDimensionOptions = DISTRIBUTION_DIMENSION_OPTIONS;
      this.distDimensionOptions = getCorrectLabels(
        DISTRIBUTION_DIMENSION_OPTIONS,
        ACCOUNT_SPECIFIC_DISTRIBUTION_DIMENSION_OPTIONS[this.account.id]
      );
      this.distDimensionOptions.forEach((o) => {
        if (this.payload.keywords.length > 0) {
          if (!['Ads', 'Geo'].includes(o.value)) {
            keywordsOption.push(o);
          }
        } else if (this.payload.creatives.length > 0) {
          if (!['Keywords', 'Geo'].includes(o.value)) {
            keywordsOption.push(o);
          }
        } else {
          keywordsOption.push(o);
        }
      });
      this.distDimensionOptions = keywordsOption;
      this.distRows = await appConsoleDataLayer.fetchSearchDistributionData(
        this.advertiserId,
        this.activeSolutionId,
        this.selectedDistDimension,
        this.selectedDistMetric,
        this.selectedDistPixelIds,
        this.payload
      );
      this.distLoading = false;
    },
    // add the logic for calculating the initial metric options selected for daily View chart.
    loadDailyViewData() {
      this.promises = [];
      this.prevSelectedDailyViewMetrics = [];
      this.handleDailyViewOptChange(this.selectedDailyViewMetrics);
    },
    async loadPerformanceData() {
      this.paRequestId = uuid();
      this.paLoading = true;
      this.paMetaData = this.fetchPerformanceMetaData(this.selectedPADimension);
      if (['campaign', 'adgroup'].includes(this.selectedPADimension)) {
        this.paSortBy = 'startDate';
        this.paSortDirection = 'desc';
      } else {
        this.paSortBy = '';
        this.paSortDirection = 'asc';
      }
      const response = await appConsoleDataLayer.fetchSearchDimensionPerformance(
        this.paRequestId,
        this.advertiserId,
        this.activeSolutionId,
        this.selectedPADimension,
        this.payload
      );
      if (this.paRequestId === response.requestId) {
        this.paRows = response.data;
        this.paLoading = false;
        return;
      }
      setTimeout(() => {
        this.paLoading = false;
      }, 200);
    },
    fetchPerformanceMetaData(dimension) {
      const list = [PERFORMANCE_ANALYSIS_DIMENSION_COLUMN];
      const baseOptions = PERFORMANCE_ANALYSIS_DIMENSION_TITLES;
      const accountOptions =
        ACCOUNT_SPECIFIC_PERFORMANCE_ANALYSIS_DIMENSION_TITLES[this.account.id];
      const correctLabels = getCorrectLabels(baseOptions, accountOptions);
      list[0].value = correctLabels[dimension];
      let columns = PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION[dimension];
      if (!columns) {
        columns = PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION.other;
      }
      // if via media account then remove spend
      if (this.account.id === VIA_ACCOUNT_ID) {
        columns = columns.filter(
          (options) =>
            options.key !== 'spend' &&
            options.key !== 'actualPacing' &&
            options.key !== 'budget' &&
            options.key !== 'cpc'
        );
      }
      list.push(...columns);

      return list;
    },
    handleTPOptionsChange(dimensions, metric) {
      this.prepareTopPerformerData(dimensions, metric);
    },
    prepareTopPerformerData(dimensions, metric) {
      this.selectedTPMetric = metric;

      const data = JSON.parse(JSON.stringify(this.tpData));
      const icons = {};
      this.tpDimensionOptions.forEach((o) => {
        if (o.children && o.children.length) {
          o.children.forEach((c) => {
            icons[c.id] = o.icon;
          });
        } else {
          icons[o.id] = o.icon;
        }
      });
      this.tpRows = data
        .filter((o) => _.isEmpty(dimensions) || dimensions.includes(o.dimension))
        .map((o) => {
          const { name, dimension } = o;
          const icon = icons[dimension] || '';
          const value = o[metric.toLowerCase()];
          return { name, dimension, icon, value };
        });
    },
    async handleDistOptionsChange(tab, dimension, metric, pixelIds = []) {
      this.distLoading = true;
      this.selectedDistMetric = metric;
      this.selectedDistPixelIds = pixelIds;
      this.selectedDistDimension = dimension;
      this.distDimensionOptions = this.distDimensionOptions.map((opt) => {
        if (opt.children && opt.children.length) {
          opt.children = opt.children.map((child) => {
            if (dimension === child.id) {
              child.selected = true;
            } else {
              child.selected = false;
            }
            return child;
          });
          if (opt.children.filter((child) => child.selected).length > 0) {
            opt.selected = true;
          } else {
            opt.selected = false;
          }
        } else if (dimension === opt.id) {
          opt.selected = true;
        } else {
          opt.selected = false;
        }

        return opt;
      });

      this.distRows = await appConsoleDataLayer.fetchSearchDistributionData(
        this.advertiserId,
        this.activeSolutionId,
        this.selectedDistDimension,
        this.selectedDistMetric,
        this.selectedDistPixelIds,
        this.payload
      );
      this.distLoading = false;
    },

    // the function is used to get values from the api for daily view chart options
    // opts: array of selected options i.e. ['Impressions', 'Spend'] etc.
    async handleDailyViewOptChange(metrics) {
      this.dailyViewLoading = true;
      this.selectedDailyViewMetrics = metrics;

      if (this.selectedDailyViewMetrics.length === 0) {
        this.promises = [];
        this.dailyViewColumns = [];
        this.dailyViewLoading = false;
        this.prevSelectedDailyViewMetrics = this.selectedDailyViewMetrics;
        return;
      }
      const metricObjectMap = {};
      this.dailyViewMetricOptions
        .filter((m) => metrics.includes(m.id))
        .forEach((m) => {
          metricObjectMap[m.id] = m;
        });
      // 1. Check if new selection done from drop down
      if (this.prevSelectedDailyViewMetrics.length <= this.selectedDailyViewMetrics.length) {
        const newPromises = [];
        metrics.forEach((m) => {
          let findObj = null;
          this.promises.forEach(async (promiseVal) => {
            const fulfiled = await promiseVal;
            if (fulfiled.id === m) {
              findObj = promiseVal;
            }
          });
          if (findObj) {
            newPromises.push(findObj);
          } else {
            newPromises.push(this.fetchDailyViewData(m));
          }
          return m;
        });
        this.promises = [...newPromises];
      }
      const columnData = this.promises.length > 0 ? await Promise.all(this.promises) : [];

      let existingColumnData = [];
      // 2) One element is unselected so create existingColumnData that will have the selected
      // element only after filtering from columnData
      if (this.prevSelectedDailyViewMetrics.length > this.selectedDailyViewMetrics.length) {
        existingColumnData =
          this.selectedDailyViewMetrics.length === 0
            ? []
            : columnData.filter((f) => this.selectedDailyViewMetrics.includes(f.id));
        existingColumnData.forEach((c, ci) => {
          c.id = metricObjectMap[metrics[ci]]?.id;
          c.legendName = metricObjectMap[metrics[ci]]?.value;
          c.legendColor = metricObjectMap[metrics[ci]]?.legendColor;
          c.legendValueTypes = getValueFormat(metrics[ci]);
        });
        // 3) update the promises to hold the selected data only
        this.promises = existingColumnData;
        this.dailyViewColumns = existingColumnData.filter(
          (data) => data.currentDaysData.length > 0
        );
      } else {
        // 4) One element is selected which is present in columnData
        columnData.forEach((c, ci) => {
          c.id = metricObjectMap[metrics[ci]]?.id;
          c.legendName = metricObjectMap[metrics[ci]]?.value;
          c.legendColor = metricObjectMap[metrics[ci]]?.legendColor;
          c.legendValueTypes = getValueFormat(metrics[ci]);
        });
        this.dailyViewColumns = columnData.filter((data) => data.currentDaysData.length > 0);
      }
      this.dailyViewLoading = false;
      this.prevSelectedDailyViewMetrics = this.selectedDailyViewMetrics;
    },
    // the function is used to fetch the daily view data based on selected metric.
    async fetchDailyViewData(metric) {
      const selectedMeasurement = ['roas', 'cpa', 'ctr'].includes(metric) ? undefined : metric;
      const selectedMetric = ['roas', 'cpa', 'ctr'].includes(metric) ? metric : undefined;

      let data = '';
      if (selectedMeasurement) {
        data = await appConsoleDataLayer.fetchSearchMeasurementDailyViewData(
          this.advertiserId,
          this.activeSolutionId,
          selectedMeasurement,
          this.payload
        );
        return this.processMeasurementResults(data, selectedMeasurement);
      }
      data = await appConsoleDataLayer.fetchSearchMetricDailyViewData(
        this.advertiserId,
        this.activeSolutionId,
        selectedMetric,
        this.payload
      );
      return this.processMetricResults(data, selectedMetric);
    },
    async handlePATabClick(paTab) {
      this.paLoading = true;
      this.selectedPADimension = paTab;
      await this.loadPerformanceData();
    },
    handlePaSortDirectionChange(direction) {
      this.paSortDirection = direction;
    },
    handleSortByChange(value) {
      this.paSortBy = value;
    },
  },
};
</script>
<style lang="scss">
.def-box {
  padding: 20px 4px 10px 25px;
  max-height: 354px;
  z-index: 10;
  position: relative;
  min-height: 150px;
}
.expan {
  &.expd {
    height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .tpl_itm_label2 {
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
}
::v-deep .tpl_itm_wrap {
  width: calc(25% - 22px);
  padding: 15px 25px 13px;
}
#performanceAnalysis {
  scroll-margin: 75px;
}

.bdc_main_wrap.whero {
  /* margin-top: 70px; */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.body-content.fullmax .thw_content {
  max-width: 1600px;
}
.thw_content {
  max-width: 1270px;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0px;
  position: relative;
  z-index: 11;
}
.sopened .thw_content {
  max-width: 1160px;
}
.internal-sub-nav {
  display: inline-block;
  float: none;
  margin: 11px 20px 31px 5px;
  display: block;
}
.isn-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarydark2);
  position: relative;
  cursor: pointer;
}

.isn-item.active {
  color: var(--primarydark);
}

.isn-item.active::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarycolor);
}

.isn-item:hover {
  color: var(--primarydark);
}

.isn-item:hover::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarydark2);
}

@media (max-width: 900px) {
  .bdc_top_panel_wrap {
    flex-wrap: wrap !important;
  }
}
.bdc_top_panel_wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 3;
  gap: 10px;
}

::v-deep.bdc_top_panel_wrap .pnl_itm_title_txt {
  padding-bottom: 2px;
  white-space: nowrap;
}

.bdc_panel {
  padding: 19px 25px 14px 25px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(0, 40, 60, 0.07);
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgb(165 173 178 / 22%);
}
@media (max-width: 900px) {
  .bdc_panel.two-thirds {
    width: 100% !important;
  }
}

.bdc_panel.two-thirds {
  width: calc(65% - 15px);
  display: inline-block;
}
@media (max-width: 900px) {
  .bdc_panel.one-thirds {
    width: 100% !important;
  }
}
.bdc_panel.one-thirds {
  width: calc(35% - 15px);
  display: inline-block;
}

.bdc_panel.last {
  float: right;
}

.bdc_panel .dropdown {
  border: 1px solid #c8d3e4;
}
</style>
