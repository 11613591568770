<template>
  <div class="icon-wrap" :data-name="elementName" @click="onClick">
    <k-tooltip :tooltip="tooltip" :placement="placement" :offset="offset">
      <!-- Pass through tooltip to k-tooltip -->
      <slot />
      <template #content>
        <font-awesome-icon
          :class="{ disabled }"
          :icon="icon"
          size="1x"
          aria-describedby="tooltip"
        />
      </template>
    </k-tooltip>
  </div>
</template>

<script>
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import tooltipMixin from '../mixins/tooltip-mixin';
import elementsMixin from '../mixins/elements-mixin';

export default {
  name: 'KIconTooltip',

  mixins: [tooltipMixin, elementsMixin],

  props: {
    icon: {
      required: true,
      type: Array,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    elementName() {
      if (isBlank(this.tooltip)) {
        return undefined;
      }
      return commonHelper.elementName(this.tooltip);
    },
  },

  methods: {
    onClick(ev) {
      if (this.disabled) {
        return;
      }
      this.$emit('click', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrap svg {
  &:not(.disabled):hover {
    color: #00abb7;
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
