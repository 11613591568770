import { get } from 'vuex-pathify';
import config from '~/config';

const computed = {
  user: get('common/currentUser'),
  advertiser: get('common/advertiser'),
};

const methods = {
  getGAEventCommonPayload(includeAdv) {
    const currentUser = this.user || { id: 0, userName: 'unknown', email: '' };
    const currentAdvertiser = this.advertiser || { id: 0, name: 'unknown', organizationId: 0 };
    const retVal = {
      user_id: currentUser.id,
      user_name: currentUser.userName,
      user_email: currentUser.email,
    };
    if (includeAdv) {
      retVal.adv_id = currentAdvertiser.id;
      retVal.adv_name = currentAdvertiser.name;
      retVal.adv_org_id = currentAdvertiser.organizationId;
    }
    return retVal;
  },
  fireGAEvent(eventName, eventBody = {}, includeAdv = true) {
    if (includeAdv) {
      includeAdv = !eventBody.adv_id; // If advertiser already given, skip.
    }
    if (config.gaEnabled()) {
      const gaEv = {
        ...eventBody,
        ...this.getGAEventCommonPayload(includeAdv),
      };
      this.$gtag.event(eventName, gaEv);
    }
  },
};

export default {
  computed,
  methods,
};
