/**
 * search-constant.js
 *
 * This file includes constant values required for Search solution.
 * This may include enums, reference IDs or data. For example:
 *
 * - data like the list of emails to show in various dropdowns
 * - account IDs for enabling specific functionality
 */
export const DISTRIBUTION_DIMENSION_OPTIONS = [
  { value: 'Campaign', id: 'campaign', icon: 'check', selected: false },
  { value: 'Ad Group', id: 'adgroup', icon: 'check', selected: false },
  {
    value: 'Ads',
    id: 'ads',
    icon: 'rectangle-ad',
    selected: false,
    children: [
      {
        value: 'Ad',
        id: 'ad',
        selected: false,
      },
      {
        value: 'Ad Type',
        id: 'adType',
        selected: false,
      },
    ],
  },
  {
    value: 'Keywords',
    id: 'key',
    icon: 'font',
    selected: false,
    children: [
      {
        value: 'Keywords',
        id: 'keyword',
        selected: false,
      },
      {
        value: 'Keyword/Matchtype',
        id: 'keywordMatchType',
        selected: false,
      },
    ],
  },
  {
    value: 'Geo',
    id: 'geo',
    icon: 'map-marker-alt',
    selected: false,
    children: [
      {
        value: 'Country',
        id: 'country',
        selected: false,
      },
      {
        value: 'State',
        id: 'state',
        selected: false,
      },
      {
        value: 'City',
        id: 'city',
        selected: false,
      },
      {
        value: 'DMA',
        id: 'dma',
        selected: false,
      },
      {
        value: 'County',
        id: 'county',
        selected: false,
      },
      {
        value: 'Zip Code',
        id: 'zip',
        selected: false,
      },
    ],
  },
];

export const ACCOUNT_SPECIFIC_DISTRIBUTION_DIMENSION_OPTIONS = {
  73: [
    { value: 'Contract ID', id: 'campaign', icon: 'check', selected: false },
    { value: 'ConLine ID', id: 'adgroup', icon: 'check', selected: false },
  ],
};
export const DISTRIBUTION_MEASUREMENT_OPTIONS = [
  { id: 'impressions', value: 'IMPs' },
  { id: 'spend', value: 'Spend' },
  { id: 'clicks', value: 'Clicks' },
];

export const DAILY_VIEW_METRIC_OPTIONS = [
  { id: 'impressions', value: 'Impressions', legendColor: '#00abb7', checked: true },
  { id: 'spend', value: 'Spend', legendColor: '#e23d56', checked: false },
  { id: 'clicks', value: 'Clicks', legendColor: '#ff8f3a', checked: false },
  { id: 'ctr', value: 'CTR', legendColor: '#ffd151', checked: false },
];

export const ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS = [
  { id: 'campaign', value: 'Active Campaigns' },
  { id: 'adgroup', value: 'Active Ad Groups', selected: true },
];
export const ACCOUNT_SPECIFIC_ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Active Contract ID' },
    { id: 'adgroup', value: 'Active ConLine ID', selected: true },
  ],
};
export const CAMPAIGN_SUB_TAB_OPTIONS = [
  { id: 'campaign', value: 'Campaigns' },
  { id: 'adgroup', value: 'Ad Groups', selected: true },
];

export const ACCOUNT_SPECIFIC_CAMPAIGN_SUB_TAB_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Contract ID' },
    { id: 'adgroup', value: 'ConLine ID', selected: true },
  ],
};

export const ADS_SUB_TAB_OPTIONS = [
  { id: 'ad', value: 'Ad' },
  { id: 'adType', value: 'Ad Type' },
];

export const KEYWORDS_SUB_TAB_OPTIONS = [
  { id: 'keyword', value: 'Keywords' },
  { id: 'keywordMatchType', value: 'Keywords/MatchType' },
];

export const GEO_SUB_TAB_OPTIONS = [
  { id: 'country', value: 'Country' },
  { id: 'state', value: 'State', selected: true },
  { id: 'city', value: 'City' },
  { id: 'dma', value: 'DMA' },
  { id: 'county', value: 'County' },
  { id: 'zip', value: 'Zip Code' },
];

export const DELIVERY_TAB_OPTIONS = [
  {
    id: 'campaign_adgroup',
    value: 'Campaigns & Ad Groups',
    subTabs: ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS,
    selected: true,
  },
  { id: 'ads', value: 'Ads', subTabs: ADS_SUB_TAB_OPTIONS },
  { id: 'keywords', value: 'Keywords', subTabs: KEYWORDS_SUB_TAB_OPTIONS },
  { id: 'geo', value: 'Geo', subTabs: GEO_SUB_TAB_OPTIONS },
  {
    id: 'ad-previews',
    value: 'Ad Previews & Ad Groups',
    viewUrl: '/views/VIAMedia-SearchAdPreviews/CustomReport',
  },
];

export const ACCOUNT_SPECIFIC_DELIVERY_TAB_OPTIONS = {
  73: [
    {
      id: 'campaign_adgroup',
      value: 'Contract ID & ConLine ID',
      subTabs: [
        { id: 'campaign', value: 'Active Contract ID' },
        { id: 'adgroup', value: 'Active ConLine ID', selected: true },
      ],
      selected: true,
    },
  ],
};

export const DELIVERY_METRIC_OPTIONS = [
  { id: 'ctr', value: 'CTR' },
  { id: 'cpc', value: 'CPC' },
];

export const PERFORMANCE_ANALYSIS_DIMENSION_COLUMN = {
  key: 'name',
  value: 'Campaign Name',
  align: 'left',
};

export const PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS = [
  {
    key: 'startDate',
    value: 'Start',
    align: 'left',
    formatter: { type: 'datetime', format: 'M/D/YYYY' },
  },
  {
    key: 'endDate',
    value: 'End',
    align: 'left',
    formatter: { type: 'datetime', format: 'M/D/YYYY' },
  },
  {
    key: 'budget',
    value: 'Budget',
    align: 'right',
    formatter: { type: 'number', format: '$0,0.[00]' },
  },
  {
    key: 'status',
    value: 'Status',
    align: 'left',
    formatter: { type: 'status' },
  },
  {
    key: 'actualPacing',
    value: 'Pacing',
    align: 'center',
    formatter: { type: 'pacing', format: '0%' },
  },
];

export const PERFORMANCE_ANALYSIS_COLUMNS = [
  {
    key: 'spend',
    value: 'Spend',
    align: 'right',
    formatter: { type: 'number', format: '$0,0.[00]' },
  },
  {
    key: 'impressions',
    value: 'IMPs',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'clicks',
    value: 'Clicks',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'ctr',
    infinityKey: 'ctrInfinity',
    value: 'CTR',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[00]%' },
  },
  {
    key: 'cpc',
    infinityKey: 'cpcInfinity',
    value: 'CPC',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
];

export const PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION = {
  campaign: [...PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS, ...PERFORMANCE_ANALYSIS_COLUMNS],
  adgroup: [...PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS, ...PERFORMANCE_ANALYSIS_COLUMNS],
  keyword: [...PERFORMANCE_ANALYSIS_COLUMNS],
  keywordMatchType: [...PERFORMANCE_ANALYSIS_COLUMNS],
  country: [...PERFORMANCE_ANALYSIS_COLUMNS],
  state: [...PERFORMANCE_ANALYSIS_COLUMNS],
  dma: [...PERFORMANCE_ANALYSIS_COLUMNS],
  city: [...PERFORMANCE_ANALYSIS_COLUMNS],
  county: [...PERFORMANCE_ANALYSIS_COLUMNS],
  zip: [...PERFORMANCE_ANALYSIS_COLUMNS],
  other: [...PERFORMANCE_ANALYSIS_COLUMNS],
};

export const PERFORMANCE_ANALYSIS_DIMENSION_TITLES = {
  campaign: 'Campaign Name',
  adgroup: 'Ad Group Name',
  ad: 'Ad',
  adType: 'Ad Type',
  keyword: 'Keyword',
  keywordMatchType: 'Keyword/Matchtype',
  country: 'Country',
  state: 'State',
  dma: 'DMA',
  city: 'City',
  county: 'County',
  zip: 'Zip Code',
};

export const ACCOUNT_SPECIFIC_PERFORMANCE_ANALYSIS_DIMENSION_TITLES = {
  73: {
    campaign: 'Contract ID Name',
    adgroup: 'ConLine ID Name',
  },
};

export const TP_DIMENSION_OPTIONS = [
  {
    value: 'Ads',
    id: 'ads',
    icon: 'rectangle-ad',
    selected: true,
    children: [
      {
        value: 'Ad',
        id: 'ad',
        selected: true,
      },
      {
        value: 'Ad Type',
        id: 'adType',
        selected: true,
      },
    ],
  },
  {
    value: 'Keywords',
    id: 'key',
    icon: 'font',
    selected: true,
    children: [
      {
        value: 'Keywords',
        id: 'keyword',
        selected: true,
      },
      {
        value: 'Keyword/Matchtype',
        id: 'keywordMatchType',
        selected: true,
      },
    ],
  },
];

export const REPORT_DIMENSION_OPTIONS = [
  { id: 'overall', value: 'Overall' },
  { id: 'day', value: 'Day' },
  { id: 'campaign', value: 'Campaign' },
  { id: 'adgroup', value: 'Ad Group' },
  { id: 'ad', value: 'Ad' },
  { id: 'adType', value: 'Ad Type' },
  { id: 'keyword', value: 'Keyword' },
  { id: 'keywordMatchType', value: 'Keyword/Matchtype' },
  { id: 'matchType', value: 'Matchtype' },
  { id: 'country', value: 'Country' },
  { id: 'state', value: 'State' },
  { id: 'dma', value: 'DMA' },
  { id: 'city', value: 'City' },
  { id: 'county', value: 'County' },
  { id: 'zipcode', value: 'Zip Code' },
];

export const ACCOUNT_SPECIFIC_REPORT_DIMENSION_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Contract ID' },
    { id: 'adgroup', value: 'ConLine ID' },
  ],
};

export const REPORT_DIMENSION_COLUMN = {
  ...PERFORMANCE_ANALYSIS_DIMENSION_COLUMN,
};
export const REPORT_METRIC_OPTIONS = [...PERFORMANCE_ANALYSIS_COLUMNS];

export const REPORT_DIMENSION_TITLES = {
  overall: 'Overall',
  day: 'Day',
  campaign: 'Campaign Name',
  adgroup: 'Ad Group Name',
  ad: 'Ad',
  adType: 'Ad Type',
  keyword: 'Keyword',
  keywordMatchType: 'Keyword/Matchtype',
  matchType: 'Matchtype',
  country: 'Country',
  state: 'State',
  dma: 'DMA',
  city: 'City',
  county: 'County',
  zipcode: 'Zip Code',
};

export const ACCOUNT_SPECIFIC_REPORT_DIMENSION_TITLES = {
  73: {
    campaign: 'Contract ID Name',
    adgroup: 'ConLine ID Name',
  },
};
