<template>
  <div
    ref="b-dropdown-flat"
    class="multiselect-dropdown noselect"
    style="width: 100%; max-width: 100%;"
    tabindex="1"
    :class="{ disabled }"
  >
    <div
      :id="componentId"
      class="select pe-select"
      :class="[showDropdown ? 'open' : 'closed']"
      @click="showDropdown = !showDropdown"
    >
      <span class="top-span">{{ selectedOptions }}</span>
      <font-awesome-icon
        :icon="['far', 'chevron-down']"
        class="droparw"
        :class="showDropdown ? 'rotate-chevron' : ''"
      />
    </div>
    <ul
      v-if="showDropdown"
      :id="dropdownId"
      :class="[allowMultiSelect ? 'dropdown-container-multi' : 'dropdown-container-menu']"
    >
      <li
        v-for="(option, i) in optionsCopy"
        :key="i"
        class="pe pe3 drp-itm2 dropdown-content-selected"
        :class="[option.disabled ? 'disabled' : '', expandedOpt[option.id] ? 'expanded' : '']"
        @mouseover="(el) => handleMouseOver(el, i + 1, option.value || option)"
        @mouseleave="handleMouseLeave"
      >
        <div class="opt-container">
          <div
            class="main-opt-container"
            :class="[!allowMultiSelect && selectedOptionIndex === i ? 'selected-opt-val' : '']"
            @click="onOptionClick(option, i)"
          >
            <div class="main-opt-value">
              <font-awesome-icon
                v-if="allowMultiSelect"
                class="drop-ck show left"
                :class="{ 'check-off': !option.selected }"
                :icon="['fa', 'check']"
                size="6x"
              />
              <font-awesome-icon
                v-if="option.icon && option.icon !== 'check'"
                class="sbm_i"
                :icon="['fas', option.icon]"
              />
              <font-awesome-icon
                v-if="option.icon && option.icon === 'check'"
                class="drop-ck show left"
                :class="{ 'check-off': !option.selected }"
                :icon="['fa', 'check']"
                size="6x"
              />
              <span class="norm-option-item">{{ option.value || option }}</span>
            </div>
            <font-awesome-icon
              v-if="numOfChildren(option.children) > 0"
              :icon="['far', 'chevron-down']"
              class="droparw sub-opt-arrow"
              :class="expandedOpt[option.id] ? 'rotate-chevron' : ''"
            />
          </div>
          <ul
            v-if="expandedOpt[option.id] && numOfChildren(option.children) > 0"
            :id="dropdownId"
            class="sub-opt-container"
          >
            <li
              v-for="(child, j) in option.children"
              :key="j"
              class="pe pe3 drp-itm2 dropdown-content-selected sub-opt-value"
              :class="[option.disabled ? 'disabled' : '']"
              @click="onSubOptionClick(option, i, j)"
              @mouseover="(el) => handleMouseOver(el, j + 1, child.value || child)"
              @mouseleave="handleMouseLeave"
            >
              <font-awesome-icon
                class="drop-ck show"
                :class="{ 'check-off': !child.selected }"
                :icon="['fa', 'check']"
                size="6x"
              />
              <span class="norm-option-item">{{ child.value || option }}</span>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { uuid } from '~/helpers/global/misc-helpers';

export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    showAll: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    componentId: {
      type: String,
      required: false,
      default: () => 'dropdownComponent',
    },
    allowMultiSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowMultiSelectChildren: {
      type: Boolean,
      required: false,
      default: false,
    },
    customSelectAllText: {
      type: String,
      required: false,
      default: 'All',
    },
    hideSelections: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    let selectedOption = '';
    if (!this.allowMultiSelect && this.options.length) {
      const defaultSelectedOption = this.options.find((t) => t.selected);
      selectedOption = defaultSelectedOption ? defaultSelectedOption.value : this.options[0].value;
    }
    return {
      showDropdown: false,
      optionsCopy: JSON.parse(JSON.stringify(this.options)) || [],
      activeEl: { style: { display: 'none' }, text: '' },
      dropdownId: uuid(),
      selectedOption,
      expandedOpt: {},
      selectedOptionIndex: this.options.findIndex((opt) => opt.selected) || -1,
    };
  },
  computed: {
    selectedOptions() {
      if (!this.allowMultiSelect) {
        return this.selectedOption;
      }
      if (this.hideSelections) {
        return '- Select Metrics -';
      }
      let result = '';
      if (this.allSelected) {
        result = 'All';
      } else {
        const resultArray = [];
        this.optionsCopy.forEach((option) => {
          if (this.numOfChildren(option.children) > 0) {
            option.children.forEach((child) => {
              if (child.selected) {
                resultArray.push(child.value);
              }
            });
          } else if (option.selected) {
            resultArray.push(option.value);
          }
        });
        result = resultArray.join(', ');
      }
      return result;
    },
    allSelected() {
      let result = true;
      this.optionsCopy.forEach((option) => {
        if (this.numOfChildren(option.children) > 0) {
          option.children.forEach((child) => {
            if (!child.selected) {
              result = false;
            }
          });
        } else if (!option.selected) {
          result = false;
        }
      });
      return result;
    },
  },
  watch: {
    options: {
      deep: true,
      handler(n) {
        this.optionsCopy = JSON.parse(JSON.stringify(n));
        if (!this.allowMultiSelect && n.length) {
          const defaultSelectedOption = n.find((t) => t.selected);
          this.selectedOption = defaultSelectedOption ? defaultSelectedOption.value : n[0].value;
        }
        this.selectedOptionIndex = n.findIndex((opt) => opt.selected) || -1;
      },
    },
    disabled(newVal, oldVal) {
      if (newVal !== oldVal && newVal && this.showDropdown) {
        this.showDropdown = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    numOfChildren(array) {
      if (!array) {
        return 0;
      }
      return array.length;
    },
    onOptionClick(option, index) {
      this.selectedOptionIndex = index;
      if (this.numOfChildren(option.children) > 0) {
        this.expandedOpt = { ...this.expandedOpt, [option.id]: !this.expandedOpt[option.id] };
      } else if (this.allowMultiSelect) {
        option.selected = !option.selected;
        this.handleMouseLeave();
        this.optionsCopy = JSON.parse(JSON.stringify(this.optionsCopy));
        this.$emit('selected-options', this.optionsCopy, false, index);
      } else if (this.allowMultiSelectChildren) {
        this.selectedOption = option.value || option;
        const optCopy = this.optionsCopy.map((opt, optInd) => {
          opt.selected = optInd === index;
          return opt;
        });
        this.optionsCopy = JSON.parse(JSON.stringify(optCopy));
        this.$emit('selected-options', this.optionsCopy, false, index);
      } else {
        this.selectedOption = option.value || option;
        this.$emit('on-change', option.id || option, option.value || option);
      }
    },
    onSubOptionClick(option, parentOptIndex, childOptIndex) {
      this.selectedOptionIndex = parentOptIndex;
      if (this.allowMultiSelect) {
        this.optionsCopy[parentOptIndex].children[childOptIndex].selected = !this.optionsCopy[
          parentOptIndex
        ].children[childOptIndex].selected;
        const selectedChildren = this.optionsCopy[parentOptIndex].children.filter(
          (child) => child.selected
        );
        if (selectedChildren.length === 0) {
          this.optionsCopy[parentOptIndex].selected = false;
        } else {
          this.optionsCopy[parentOptIndex].selected = true;
        }
        this.optionsCopy = JSON.parse(JSON.stringify(this.optionsCopy));
        this.handleMouseLeave();
        this.$emit('selected-options', this.optionsCopy, false, childOptIndex);
      } else if (this.allowMultiSelectChildren) {
        this.optionsCopy[parentOptIndex].children[childOptIndex].selected = !this.optionsCopy[
          parentOptIndex
        ].children[childOptIndex].selected;
        this.optionsCopy[parentOptIndex].selected = true;
        this.selectedOption = this.optionsCopy[parentOptIndex].value || option;
        this.$emit('selected-options', this.optionsCopy, false, childOptIndex);
      } else {
        this.selectedOption = option.children[childOptIndex].value || option;
        const optCopy = this.optionsCopy.map((opt, optInd) => {
          if (this.numOfChildren(opt.children) > 0) {
            opt.children.map((child, ind) => {
              if (optInd === parentOptIndex) {
                if (ind === childOptIndex) {
                  child.selected = !child.selected;
                } else {
                  child.selected = false;
                }
              } else {
                child.selected = false;
              }
              return child;
            });
            if (opt?.children?.filter((child) => child.selected).length === 0) {
              opt.selected = false;
            }
          } else if (optInd === parentOptIndex) {
            opt.selected = !opt.selected;
          } else {
            opt.selected = false;
          }
          return opt;
        });
        this.optionsCopy = JSON.parse(JSON.stringify(optCopy));
        this.$emit(
          'on-change',
          option.children[childOptIndex].id || option,
          option.children[childOptIndex].value || option
        );
      }
    },
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    },
    closeDropdown() {
      this.showDropdown = false;
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    handleMouseOver(el, i, text) {
      const ulElement = document.getElementById(this.dropdownId);
      if (!text) {
        return;
      }
      this.activeEl = {
        style: {
          top: `${i * 39.2 - ulElement.scrollTop + 80}px`,
          left: `${25}px`,
          display: 'block',
        },
        text,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    selectAll() {
      const selectedOptions = JSON.parse(JSON.stringify(this.optionsCopy));

      selectedOptions.forEach((option) => {
        if (option.disabled) {
          return;
        }
        option.selected = !this.allSelected;
      });

      this.optionsCopy = selectedOptions;

      this.$emit('selected-options', this.optionsCopy, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.left-border {
  width: 5px;
  height: 100%;
}
.check {
  width: 1em;
  padding: 2px;
  color: #00b3aa;
  border-radius: 30px;
  box-shadow: inset 0px 0px 1px 1px #2aa4af70;
  &.left {
    margin-right: 0.5rem;
  }
  &.wl {
    color: var(--primarycolor) !important;
  }
}
.check-off {
  color: transparent !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.selected-opt-header {
  font-size: 14px;
  color: #707e8a;
}
.selected-opt-wrapper {
  display: inline-flex;
  width: 90%;
  white-space: nowrap;
}
.selected-opt {
  display: inline-block;
  width: 90%;
  margin-left: 0.2rem;
  overflow: hidden;
  font-size: 14px;
  color: #cad1d6;
  text-overflow: ellipsis;
  white-space: nowrap;
}
svg.reverse {
  transform: rotate(180deg);
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

.sub-opt-container {
  background: rgb(116 116 143 / 5%);
  & li:first-child {
    border-top: 1px solid #2b355814;
  }
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  * {
    outline: 0;
  }
  .multiselect-dropdown {
    min-width: 150px;
    max-width: 240px;
    display: inline-block;
    border-radius: 6px;
    transition: all 0.3s ease;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: var(--primarydark2);
    text-align: left;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Opera and Firefox */
  }
  .multiselect-dropdown .select {
    display: block;
    cursor: pointer;
    padding: 8px 20px 8px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .droparw {
    transition: all 0.2s ease-in-out;
    &.sub-opt-arrow {
      position: relative;
      right: 10px !important;
    }
  }
  .droparw.rotate-chevron {
    transform: rotate(180deg);
  }
  .multiselect-dropdown .dropdown-container-menu,
  .multiselect-dropdown .dropdown-container-multi {
    position: absolute;
    left: 0;
    z-index: 999;
    width: calc(100% + 2px);
    min-width: 220px;
    max-height: 338px;
    margin-top: 0px;
    margin-left: -1px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 0 1px 4px 4px;
    box-shadow: 0px 5px 3px 1px rgb(115 124 143 / 10%);
    border-right: 1px solid #c6d2d8;
    border-left: 1px solid #c6d2d8;
    border-bottom: 1px solid #c6d2d8;
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px;
    }
  }
  .multiselect-dropdown .dropdown-container-menu li,
  .multiselect-dropdown .dropdown-container-multi li {
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    border-bottom: 1px solid #2b355814;
    &:last-child {
      border-bottom: 0px;
    }
    & .norm-option-item {
      margin-left: 5px;
    }
  }

  .multiselect-dropdown .dropdown-container-multi li.dropdown-content-selected,
  .multiselect-dropdown .dropdown-container-menu li.dropdown-content-selected {
    display: flex;
    align-items: center;
    &.sub-opt-value {
      padding: 10px 0px;
      padding-left: 26px;
    }
    &.expanded {
      padding-bottom: 0px !important;
    }
    .opt-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .main-opt-container {
        &.selected-opt-val {
          border-left: 5px solid var(--primarycolor);
        }
        border-left: 5px solid transparent;
        padding: 10px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .main-opt-value {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
  .multiselect-dropdown .dropdown-container-menu,
  .multiselect-dropdown .dropdown-container-multi {
    padding: 0;
    list-style: none;
  }
  .multiselect-dropdown .dropdown-container-menu,
  .multiselect-dropdown .dropdown-container-multi {
    padding: 0px 0px 0px 0px;
    list-style: none;
  }
  .multiselect-dropdown .dropdown-container-menu li:hover,
  .multiselect-dropdown .dropdown-container-multi li:hover {
    white-space: normal;
    background-color: rgb(116 116 143 / 6%) !important;
  }
  .multiselect-dropdown .dropdown-container-menu li:hover,
  .multiselect-dropdown .dropdown-container-multi li:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .multiselect-dropdown .dropdown-container-menu li:active,
  .multiselect-dropdown .dropdown-container-multi li:active {
    background-color: #333;
  }

  .drop-ck {
    float: right;
    position: relative;
    color: var(--primarycolor);
    border: 0px solid;
    box-shadow: inset 0px 0px 1px 1px var(--primarycolor);
    padding: 2px 3px 3px;
    left: 8px;
    background: transparent;
    font-size: 11px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
    width: 11px;
    height: 11px;
  }

  .dropdown-container-menu .sbm_i,
  .dropdown-container-multi .sbm_i {
    float: none;
    background: transparent;
    display: inline-block;
    margin-right: 4px;
    margin-left: 10px;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}
</style>
