var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{ref:"table-scroll",staticClass:"panel_scroll_wrap table-scroll",class:_vm.isTableExpanded ? 'reactive-ht' : 'height499',staticStyle:{"overflow-x":"scroll"}},[(!_vm.loading && _vm.rows.length)?_c('div',{staticClass:"tg-wrap"},[_c('table',{ref:"ddDivRef",staticClass:"tg hm_table",attrs:{"id":"tg-wqhPh"}},[_c('thead',[_c('tr',_vm._l((_vm.metaData),function(col,j){return _c('th',{key:j,staticClass:"tg-ul38",class:{
                'tg-sort-header': col.allowSorting,
                firstCol: j === 0,
                firstColExpanded: j === 0 && _vm.isTableExpanded,
                rt: col.align === 'right',
                ctr: col.align === 'center',
                'tg-sort-asc': col.allowSorting && _vm.sortBy === col.key && _vm.sortDirection === 'desc',
                'tg-sort-desc': col.allowSorting && _vm.sortBy === col.key && _vm.sortDirection === 'asc',
              },on:{"click":function($event){return _vm.clickHandler(col)}}},[_vm._v(" "+_vm._s(col.value)+" ")])}),0)]),_c('tbody',_vm._l((_vm.sortedRows),function(row,i){return _c('tr',{key:i,class:{ rowhover: i === _vm.hoveredRowIndex },style:({ position: 'relative' }),attrs:{"id":i},on:{"mouseover":function($event){return _vm.handleMouseOverRow(i)},"mouseleave":_vm.handleMouseLeaveRow}},_vm._l((_vm.metaData),function(col,j){return _c('td',{key:j,ref:`${i}_${j}_metadata`,refInFor:true,staticClass:"tg-0lax",class:{
                firstCol: j === 0,
                firstColExpanded: j === 0 && _vm.isTableExpanded,
                rt: col.align === 'right',
              },on:{"mouseover":(el) =>
                  _vm.handleMouseOver(
                    el,
                    _vm.formattedColumnValue(row, col),
                    `${i}_${j}_metadata`,
                    j === 0
                  ),"mouseleave":_vm.handleMouseLeave}},[(col.key === 'actions')?_c('div',{key:`actions_${j}`},[_c('div',{staticClass:"row middle-xs end-xs"},[_c('div',{staticClass:"icon-selector"},[_c('k-icon-tooltip',{attrs:{"icon":['fas', 'eye'],"tooltip":"View","offset":20},on:{"click":function($event){return _vm.handleActionClick(row)}}})],1)])]):(col.formatter && col.formatter.type === 'status')?_c('div',{key:row[col.key],staticClass:"tgs-status",class:`tgs-${row[col.key].toLowerCase()}`},[_vm._v(" "+_vm._s(row[col.key])+" ")]):[_vm._v(" "+_vm._s(_vm.formattedColumnValue(row, col))+" ")]],2)}),0)}),0)])]):_vm._e(),(!_vm.loading && !_vm.rows.length)?_c('div',{staticClass:"txt-center",staticStyle:{"margin-top":"13px"}},[_vm._v(" No data is available to show ")]):_vm._e(),(_vm.loading)?_c('b-loading-spinner',{staticClass:"txt-center"}):_vm._e()],1),(_vm.showDownloadOptions)?_c('a-export-dropdown',{attrs:{"top":_vm.top,"left":_vm.left,"menu-items":_vm.menuItems},on:{"type-selected":_vm.downloadReport,"close":function($event){_vm.showDownloadOptions = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }