/**
 * social-constant.js
 *
 * This file includes constant values required for Meta solution.
 * This may include enums, reference IDs or data. For example:
 *
 * - data like the list of emails to show in various dropdowns
 * - account IDs for enabling specific functionality
 */
export const DISTRIBUTION_DIMENSION_OPTIONS = [
  { id: 'campaign', value: 'Campaign', selected: true },
  { id: 'adSet', value: 'Ad Set', selected: false },
  { id: 'ad', value: 'Ad', selected: false },
  { id: 'placement', value: 'Placement', selected: false },
];

export const ACCOUNT_SPECIFIC_DISTRIBUTION_DIMENSION_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Contract ID', selected: true },
    { id: 'adSet', value: 'ConLine ID', selected: false },
  ],
};

export const DISTRIBUTION_MEASUREMENT_OPTIONS = [
  { id: 'impressions', value: 'IMPs', selected: true },
  { id: 'spend', value: 'Spend', selected: false },
  { id: 'clicks', value: 'Clicks', selected: false },
  { id: 'completions', value: 'Completed Views', selected: false },
  { id: 'conversions', value: 'Conversions', selected: false },
  { id: 'revenue', value: 'Revenue', selected: false },
];

export const DAILY_VIEW_METRIC_OPTIONS = [
  { id: 'impressions', value: 'Impressions', legendColor: '#00abb7', checked: true },
  { id: 'spend', value: 'Spend', legendColor: '#e23d56', checked: false },
  { id: 'clicks', value: 'Clicks', legendColor: '#ff8f3a', checked: false },
  { id: 'ctr', value: 'CTR', legendColor: '#ffd151', checked: false },
  { id: 'completions', value: 'Completed Views', legendColor: '#d4e885', checked: false },
  { id: 'vcr', value: 'VCR', legendColor: '#3eaa73', checked: false },
  { id: 'conversions', value: 'Conversions', legendColor: '#775ec7', checked: false },
  { id: 'cpa', value: 'CPA', legendColor: '#e85d52', checked: false },
  { id: 'revenue', value: 'Revenue', legendColor: '#ff6843', checked: false },
  { id: 'roas', value: 'ROAS', legendColor: '#ffaa43', checked: false },
];

export const ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS = [
  { id: 'campaign', value: 'Active Campaigns' },
  { id: 'adSet', value: 'Active Ad Sets', selected: true },
];

export const ACCOUNT_SPECIFIC_ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Active Contract ID' },
    { id: 'adSet', value: 'Active ConLine ID', selected: true },
  ],
};
export const CAMPAIGN_SUB_TAB_OPTIONS = [
  { id: 'campaign', value: 'Campaigns' },
  { id: 'adSet', value: 'Ad Sets', selected: true },
];

export const TOP_PERFORMANCE_DIMENTIONS = [
  { id: 'adSet', value: 'Ad Set', icon: 'layer-group', checked: true },
  { id: 'ad', value: 'Ad', icon: 'rectangle-ad', checked: true },
];

export const ACCOUNT_SPECIFIC_TOP_PERFORMANCE_DIMENTIONS = {
  73: [{ id: 'adSet', value: 'ConLine ID', icon: 'layer-group', checked: true }],
};

export const ACCOUNT_SPECIFIC_CAMPAIGN_SUB_TAB_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Contract ID' },
    { id: 'adSet', value: 'ConLine ID', selected: true },
  ],
};

export const DELIVERY_TAB_OPTIONS = [
  {
    id: 'campaign_adSet',
    value: 'Campaigns & Ad Sets',
    subTabs: ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS,
    selected: true,
  },
  { id: 'ad', value: 'Ad' },
  { id: 'placement', value: 'Placement' },
];

export const ACCOUNT_SPECIFIC_DELIVERY_TAB_OPTIONS = {
  73: [
    {
      id: 'campaign_adSet',
      value: 'Contract ID & ConLine ID',
      subTabs: ACCOUNT_SPECIFIC_ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS[73],
      selected: true,
    },
  ],
};

export const DELIVERY_METRIC_OPTIONS = [
  { id: 'ctr', value: 'CTR' },
  { id: 'vcr', value: 'VCR' },
  { id: 'cpc', value: 'CPC' },
  { id: 'cpcv', value: 'CPCV' },
  { id: 'cpa', value: 'CPA' },
  { id: 'roas', value: 'ROAS' },
];

export const PERFORMANCE_ANALYSIS_DIMENSION_COLUMN = {
  key: 'name',
  value: 'Campaign Name',
  align: 'left',
};

export const PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS = [
  {
    key: 'startDate',
    value: 'Start',
    align: 'left',
    formatter: { type: 'datetime', format: 'M/D/YYYY' },
  },
  {
    key: 'endDate',
    value: 'End',
    align: 'left',
    formatter: { type: 'datetime', format: 'M/D/YYYY' },
  },
  {
    key: 'budget',
    value: 'Budget',
    align: 'right',
    formatter: { type: 'number', format: '$0,0.[00]' },
  },
  {
    key: 'status',
    value: 'Status',
    align: 'left',
    formatter: { type: 'status' },
  },
  {
    key: 'actualPacing',
    value: 'Pacing',
    align: 'center',
    formatter: { type: 'pacing', format: '0%' },
  },
];

export const PERFORMANCE_ANALYSIS_COLUMNS = [
  {
    key: 'spend',
    value: 'Spend',
    align: 'right',
    formatter: { type: 'number', format: '$0,0.[00]' },
  },
  {
    key: 'impressions',
    value: 'IMPs',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'clicks',
    value: 'Clicks',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'ctr',
    infinityKey: 'ctrInfinity',
    value: 'CTR',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[00]%' },
  },
  {
    key: 'completions',
    value: 'Views',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'vcr',
    infinityKey: 'vcrInfinity',
    value: 'VCR',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[0]%' },
  },
];

export const PERFORMANCE_ANALYSIS_PLACEMENT_COLUMNS = [
  {
    key: 'impressions',
    value: 'IMPs',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'clicks',
    value: 'Clicks',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'ctr',
    infinityKey: 'ctrInfinity',
    value: 'CTR',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[00]%' },
  },
  {
    key: 'completions',
    value: 'Views',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'vcr',
    infinityKey: 'vcrInfinity',
    value: 'VCR',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[0]%' },
  },
];

export const PERFORMANCE_ANALYSIS_CONVERSIONS_COLUMNS = [
  {
    key: 'conversions',
    value: 'Conversions',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'cpa',
    infinityKey: 'cpaInfinity',
    value: 'CPA',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  // {
  //   key: 'revenue',
  //   infinityKey: 'revInfinity',
  //   value: 'Revenue',
  //   align: 'right',
  //   formatter: { type: 'number', format: '$0.[00]' },
  // },
  // {
  //   key: 'roas',
  //   infinityKey: 'roasInfinity',
  //   value: 'ROAS',
  //   align: 'right',
  //   formatter: { type: 'percentage', format: '0.[0]%' },
  // },
];
export const PERFORMANCE_ANALYSIS_REVENUES_COLUMNS = [
  {
    key: 'revenue',
    value: 'Revenue',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'roas',
    infinityKey: 'roasInfinity',
    value: 'ROAS',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[0]%' },
  },
];

export const PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION = {
  campaign: [...PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS, ...PERFORMANCE_ANALYSIS_COLUMNS],
  adSet: [...PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS, ...PERFORMANCE_ANALYSIS_COLUMNS],
  placement: [...PERFORMANCE_ANALYSIS_PLACEMENT_COLUMNS],
  other: [...PERFORMANCE_ANALYSIS_COLUMNS],
};

export const PERFORMANCE_ANALYSIS_DIMENSION_TITLES = {
  campaign: 'Campaign Name',
  adSet: 'Ad Set Name',
  ad: 'Ad',
  placement: 'Placement',
};
export const ACCOUNT_SPECIFIC_PERFORMANCE_ANALYSIS_DIMENSION_TITLES = {
  73: {
    campaign: 'Contract Id Name',
    adSet: 'ConLine ID Name',
  },
};

export const REPORT_DIMENSION_OPTIONS = [
  { id: 'overall', value: 'Overall' },
  { id: 'day', value: 'Day' },
  ...DISTRIBUTION_DIMENSION_OPTIONS,
];

export const ACCOUNT_SPECIFIC_REPORT_DIMENSION_OPTIONS = {
  73: [...ACCOUNT_SPECIFIC_DISTRIBUTION_DIMENSION_OPTIONS[73]],
};
export const REPORT_DIMENSION_COLUMN = {
  ...PERFORMANCE_ANALYSIS_DIMENSION_COLUMN,
};
export const REPORT_METRIC_OPTIONS = [
  ...PERFORMANCE_ANALYSIS_COLUMNS,
  {
    key: 'cpm',
    infinityKey: 'cpmInfinity',
    value: 'CPM',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'cpc',
    infinityKey: 'cpcInfinity',
    value: 'CPC',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'cpcv',
    infinityKey: 'cpcvInfinity',
    value: 'CPCV',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'revenue',
    infinityKey: 'revInfinity',
    value: 'Revenue',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'roas',
    infinityKey: 'roasInfinity',
    value: 'ROAS',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[0]%' },
  },
];

export const REPORT_DIMENSION_TITLES = {
  overall: 'Overall',
  day: 'Day',
  campaign: 'Campaign Name',
  adSet: 'Ad Set Name',
  ad: 'Ad',
  placement: 'Placement',
};

export const ACCOUNT_SPECIFIC_REPORT_DIMENSION_TITLES = {
  73: { campaign: 'Contract ID Name', adSet: 'ConLine ID Name' },
};
