<template>
  <div>
    <div class="tb-expand">
      <span class="expd-open" @click="handleTableExpand">
        <font-awesome-icon
          :icon="[
            'far',
            `${
              isTableExpanded
                ? 'arrow-down-left-and-arrow-up-right-to-center'
                : 'arrow-up-right-and-arrow-down-left-from-center'
            }`,
          ]"
          class="exd-icon"
        />
      </span>
    </div>
    <div class="pnl_title_right_table">
      <div class="fright" style="margin-top: -8px;">
        <b-dropdown
          :options="dimensionOptions"
          class="dimension-select"
          component-id="dimensionOptionDropdown"
          @on-change="onSelectDimension"
        />
        <b-dropdown
          :options="metricOptions"
          class="metric-select"
          component-id="metricOptionDropdown"
          :allow-multi-select="true"
          :show-all="true"
          @selected-options="onSelectMetrics"
        />
        <k-download-btn
          :preparing="downloadBtn.preparing"
          :done="downloadBtn.done"
          :class="{ loading }"
          class="dwlbtn"
          @click.native="download()"
        />
      </div>
    </div>
    <div class="pnl_itm_title">
      <div v-if="!hasTabs" class="pnl_itm_title_txt active">{{ sectionTitle }}</div>
      <template v-else>
        <div
          v-for="(tab, i) in tabOptions"
          :key="i"
          class="pnl_itm_title_txt"
          :class="{ active: tab.id === selectedTab }"
          @click="onTabClick(tab)"
        >
          {{ tab.value }}
        </div>
      </template>
    </div>
    <div
      ref="table-scroll"
      class="panel_scroll_wrap table-scroll rpt-builder"
      :class="isTableExpanded ? 'reactive-ht' : 'height499'"
      style="overflow-x: scroll;"
    >
      <div v-if="!loading && rows.length" class="tg-wrap">
        <table id="tg-wqhPh" class="tg hm_table">
          <thead>
            <tr>
              <th
                v-for="(col, j) in metaData"
                :key="j"
                :ref="`${j}_metadata`"
                class="tg-ul38 th-title-tt"
                :class="{
                  'tg-sort-header': allowSorting,
                  firstCol: j === 0,
                  firstColExpanded: j === 0 && isTableExpanded,
                  rt: col.align === 'right',
                  ctr: col.align === 'center',
                  'tg-sort-asc': allowSorting && sortBy === col.key && sortDirection === 'desc',
                  'tg-sort-desc': allowSorting && sortBy === col.key && sortDirection === 'asc',
                }"
                @click="clickHandler(col)"
                @mouseover="(e) => handleMouseOver(e, col.value, `${j}_metadata`, true, true)"
                @mouseleave="handleMouseLeave"
              >
                {{ col.value }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, i) in sortedRows"
              :id="i"
              :key="i"
              :style="{ position: 'relative' }"
              :class="{ rowhover: i === hoveredRowIndex }"
              @mouseover="handleMouseOverRow(i)"
              @mouseleave="handleMouseLeaveRow"
            >
              <td
                v-for="(col, j) in metaData"
                :key="j"
                :ref="`${i}_${j}_metadata`"
                class="tg-0lax"
                :class="{
                  firstCol: j === 0,
                  firstColExpanded: j === 0 && isTableExpanded,
                  rt: col.align === 'right',
                }"
                @mouseover="
                  (el) =>
                    handleMouseOver(
                      el,
                      formattedColumnValue(row, col),
                      `${i}_${j}_metadata`,
                      j === 0
                    )
                "
                @mouseleave="handleMouseLeave"
              >
                <pacing-chart
                  v-if="col.formatter && col.formatter.type === 'pacing'"
                  :key="row[col.key]"
                  :pacing="row[col.key]"
                />
                <div
                  v-else-if="col.formatter && col.formatter.type === 'status'"
                  :key="row[col.key]"
                  class="tgs-status"
                  :class="`tgs-${row[col.key].toLowerCase()}`"
                >
                  {{ row[col.key] }}
                </div>
                <template v-else>
                  {{ formattedColumnValue(row, col) }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-tooltip
        :tooltip-text="`${activeEl.text}`"
        :visible-style="{ ...activeEl.style, zIndex: 9999 }"
      />
      <div
        v-if="!loading && !rows.length"
        class="txt-center"
        style="margin-top: 13px;font-size: 14px !important;"
      >
        No data is available to show
      </div>
      <div class="spinner">
        <b-loading-spinner v-if="loading" class="txt-center" />
      </div>
    </div>
  </div>
</template>
<script>
import { get } from 'vuex-pathify';
import numeral from 'numeral';
import { uuid } from '@/helpers/global/misc-helpers';
import { isBlank } from 'adready-api/helpers/common';
import PacingChart from '~/components/elements/pacing-chart.vue';
import { formatDateInEST } from '~/util/apiDateFormat';
import { isFormattedValueZero, checkForDisablingConversions } from '~/util/utility-functions';
import BTooltip from '~/components/elements/b-tooltip.vue';
import BDropdown from '~/components/elements/b-dropdown.vue';

export default {
  components: {
    PacingChart,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BTooltip,
    BDropdown,
    KDownloadBtn: () =>
      import(/* webpackChunkName: "k-download-btn" */ '~/components/elements/k-download-btn.vue'),
  },
  props: {
    sectionTitle: {
      type: String,
      required: true,
    },
    dimensionOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    metricOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    rows: {
      type: Array,
      required: true,
    },
    metaData: {
      type: Array,
      required: true,
    },
    tabOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isTableExpanded: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    allowSorting: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    sortBy: {
      type: String,
      required: false,
      default: () => '',
    },
    sortDirection: {
      type: String,
      required: false,
      default: () => 'asc',
    },
  },
  data() {
    let selectedTab = '';
    if (this.tabOptions?.length) {
      const defaultSelectedTab = this.tabOptions.find((t) => t.selected);
      selectedTab = defaultSelectedTab?.id || this.tabOptions[0].id;
    }
    return {
      selectedTab,
      activeEl: { style: { display: 'none', visibility: 'hidden' }, text: '', index: -1 },
      tooltipId: uuid(),
      hoveredRowIndex: -1,
      downloadBtn: {
        preparing: false,
        done: false,
      },
    };
  },
  computed: {
    pixelOptions: get('dashboard/filters@pixelOptions'),
    hasTabs() {
      return this.tabOptions.length;
    },

    sortedRows() {
      const data = [...this.rows];
      if (!this.allowSorting) {
        return data;
      }

      return data.sort((a, b) => {
        let result = 1;

        if (this.sortDirection === 'desc') {
          result = -1;
        }
        if (a[this.sortBy] < b[this.sortBy]) {
          return -1 * result;
        }
        if (a[this.sortBy] > b[this.sortBy]) {
          return 1 * result;
        }
        return 0;
      });
    },
  },
  watch: {
    tabOptions(newTabOptions) {
      this.selectedTab = '';
      if (newTabOptions?.length) {
        const defaultSelectedTab = newTabOptions.find((t) => t.selected);
        this.selectedTab = defaultSelectedTab?.id || newTabOptions[0].id;
      }
    },
  },
  methods: {
    onSelectDimension(dimension) {
      if (checkForDisablingConversions(dimension)) {
        this.$store.set(
          'dashboard/filters@pixelOptions',
          this.pixelOptions.map((opt) => ({ ...opt, checked: true }))
        );
        this.$store.set('dashboard/isConversionDisabled', true);
      } else {
        this.$store.set('dashboard/isConversionDisabled', false);
      }
      this.$emit('on-dimension-change', dimension);
    },
    onSelectMetrics(metrics, isSelectAllClicked = false) {
      this.$emit('on-metrics-change', metrics, isSelectAllClicked);
    },
    download() {
      this.downloadBtn.preparing = true;
      try {
        this.handleExportClick();
        this.downloadBtn.done = true;
      } catch (err) {
        console.error('error downloading file ->', err);
        throw err;
      } finally {
        setTimeout(() => {
          this.downloadBtn.preparing = false;
          this.downloadBtn.done = false;
        }, 1000);
      }
    },
    handleMouseOverRow(rowIndex) {
      this.hoveredRowIndex = rowIndex; // Update the 'hoveredRowIndex' when the row is hovered
    },

    handleMouseLeaveRow() {
      this.hoveredRowIndex = -1; // Reset the 'hoveredRowIndex' when the mouse leaves the row
    },
    handleMouseOver(el, text, refKey, isShowTooltip = true, isHeading = false) {
      if (!isShowTooltip || text.length < 18) {
        return;
      }
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const elementPos = this.$refs[refKey][0];
      let tableElement;
      if (isSafari) {
        tableElement = this.$refs['table-scroll'];
      } else {
        tableElement = document.getElementsByClassName('table-scroll');
      }

      if (!elementPos || !tableElement) {
        return;
      }
      // if (!(elementPos.offsetWidth < elementPos.scrollWidth)) {
      //   return;
      // }
      const style = {
        display: 'block',
        opacity: '0.96',
        'max-width': '500px',
        width: 'max-content',
      };
      let scrollTopVal = isSafari ? tableElement.scrollTop : tableElement[0].scrollTop;
      if (isHeading) {
        scrollTopVal = isSafari ? 0 : 100;
      }
      if (isSafari) {
        style.top = `${elementPos.offsetTop - scrollTopVal + 50}px`;
        style.left = `${elementPos.offsetLeft - tableElement.scrollLeft + 70}px`;
        style.visibility = 'visible';
      } else {
        style.top = `${elementPos.offsetParent.offsetTop + scrollTopVal - 7}px`;
        style.left = `${elementPos.offsetLeft - tableElement[0].scrollLeft + 70}px`;
        style.visibility = 'visible';
      }
      this.activeEl = {
        style,
        text,
      };
    },

    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    getDimensionForExport() {
      let dimension = this.sectionTitle;
      if (this.hasTabs) {
        const tab = this.tabOptions.find((t) => t.id === this.selectedTab);
        dimension = tab.value || '';
      }
      return dimension;
    },
    handleExportClick() {
      const header = [];
      const lines = [];
      this.metaData.forEach((element) => {
        header.push(element.value);
      });
      this.sortedRows.forEach((row) => {
        const data = [];
        this.metaData.forEach((col) => {
          data.push(this.formattedColumnValue(row, col));
        });
        lines.push(data);
      });
      const csvData = [header, ...lines];
      const dimension = this.getDimensionForExport();
      const fileName = `export_${dimension}_report.csv`;
      this.$emit('on-download', fileName, csvData);
    },
    formattedColumnValue(row, col) {
      let val = row[col.key];
      const { formatter } = col;
      if (val === undefined) {
        val = ['number', 'percentage', 'pacing'].includes(formatter?.type) ? 0 : '';
      }

      if (val !== undefined && formatter) {
        if (formatter.type === 'number') {
          const isValueInfinity = !isBlank(col.infinityKey) && row[col.infinityKey];
          val = isValueInfinity ? '--' : numeral(val).format(formatter.format);
          val = isFormattedValueZero(val) ? '--' : val;
        } else if (['percentage', 'pacing'].includes(formatter.type)) {
          const isValueInfinity = !isBlank(col.infinityKey) && row[col.infinityKey];
          val = isValueInfinity ? '--' : numeral(val / 100).format(formatter.format);
          val = isFormattedValueZero(val) ? '--' : val;
        } else if (formatter.type === 'datetime') {
          val = formatDateInEST(val, formatter.format);
        }
      }

      return val;
    },
    handleTableExpand() {
      if (!this.isTableExpanded) {
        const el = document.getElementById('performanceAnalysis');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      } else {
        window.scrollBy({
          top: -50,
          behavior: 'smooth',
        });
      }
      this.$emit('on-table-expand', !this.isTableExpanded);
    },
    clickHandler(col) {
      if (!this.allowSorting) {
        return;
      }

      const colKey = col.key;
      if (this.sortBy === colKey) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = colKey;
        this.sortDirection = 'asc';
      }
    },
    onTabClick(tab) {
      this.selectedTab = tab.id;
      this.$emit('on-tab-click', this.selectedTab, tab.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.th-title-tt {
  max-width: 159px;
  min-width: 120px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.dwlbtn {
  top: 2px;
}
.pnl_itm_title {
  border-bottom: 1px solid #e0e5ee;
  padding-bottom: 0px;
  white-space: nowrap;
}

.pnl_itm_title_txt {
  display: inline-block;
  position: relative;
  border-bottom: 0px solid;
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  color: var(--primarylight);
  font-size: 1.02em;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
.pnl_itm_title_txt:hover {
  color: var(--primarydark);
}
.pnl_itm_title_txt.active {
  display: inline-block;
  position: relative;
  border-bottom: 3px solid var(--primarycolor);
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  font-weight: 600;
  color: var(--primarydark);
}

.pnl_title_right_table {
  width: calc(100% - 220px);
  text-align: right;
  float: right;
}
.fright {
  float: right;
  display: flex;
  align-content: center;
}
.dimension-select {
  width: 220px !important;
  margin: 0px 10px 0px 5px !important;
}
.metric-select {
  width: 210px !important;
  margin: 0px 10px 0px 5px !important;
}

.tb-expand {
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 11px;
  color: var(--primarylight);
  z-index: 9;
}

.tb-expand:hover {
  color: var(--primarycolor);
}

.pnl_top_icon_wrap {
  display: inline-block;
  position: relative;
  padding: 4px 0px;
}

.pnl_top_icon {
  display: inline-block;
  padding: 3px 7px;
  font-size: 16px;
  color: var(--primarylight);
}
.pnl_top_icon:hover {
  color: var(--primarycolor);
}
.panel_scroll_wrap {
  overflow-y: scroll;
  height: 100%;
  padding-right: 10px;
  overflow-x: hidden;
  &.table-scroll {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    & tr:hover {
      background-color: #f0f1f5;
    }
    & tr:nth-child(even) {
      box-shadow: inset 0px 0px 90px rgba(0, 0, 0, 0.04);
    }
  }
  &.height654 {
    height: 654px;
  }
  &.height499 {
    max-height: 499px;
  }
  &.reactive-ht {
    height: calc(100vh - 160px) !important;
    max-height: calc(100vh - 160px) !important;
  }
}

.hm_table {
  border: 0px solid #fff;
  width: 100%;
}
.hm_table tr {
  width: 100%;
  text-align: left;
  table-layout: fixed;
}
.hm_table {
  .firstCol {
    max-width: 274px;
    min-width: 274px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: var(--primarydark2);
  border-style: solid;
  border-width: 0px;
  font-size: 13px;
  font-size: 0.86em;
  padding: 13px 5px;
  word-break: normal;
  font-weight: 600;
  white-space: nowrap;
}
// .tg td:hover {
//   white-space: normal;
//   overflow: visible;
//   text-overflow: inherit;
// }
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 0px;
  font-size: 12px;
  font-weight: 600;
  overflow: visible;
  padding: 17px 5px;
  word-break: normal;
  color: var(--primarylight);
  background-color: #fbfbfb;
  padding: 10px 5px;
  padding: 14px 5px;
  white-space: nowrap;
  width: 120px;
}

.full-width .tg th {
  padding: 14px 30px 14px 15px;
}
.full-width .tg td {
  padding: 13px 30px 13px 15px;
}
/*.tg .tg-ul38{position:-webkit-sticky;position:sticky;text-align:left;top:-1px;vertical-align:top;will-change:transform;z-index: 2;}*/
.tg-ul38 {
  position: -webkit-sticky;
  position: sticky;
  text-align: left;
  top: -1px;
  vertical-align: top;
  will-change: transform;
  z-index: 2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px -6px 0px -5px #d4e1e8 !important;
  &.firstColExpanded {
    width: 274px;
  }
}
.tg-wrap tr:hover {
  background-color: #f0f1f5;
}
.tg-wrap tr:nth-child(even) {
  box-shadow: inset 0px 0px 90px rgba(0, 0, 0, 0.04);
}

.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
.tg .tg-0lax.firstColExpanded {
  width: 274px;
}
.tg-sort-header::-moz-selection {
  background: 0 0;
}
.tg-sort-header::selection {
  background: 0 0;
}
.tg-sort-header {
  cursor: pointer;
}
.tg-sort-header:after {
  content: '';
  margin: 7px 0px 0px 5px;
  border-width: 0 5px 5px;
  border-style: solid;
  border-color: #404040 transparent;
  visibility: hidden;
  position: absolute;
}
.tg-sort-header:hover:after {
  visibility: visible;
}
.tg-sort-asc:after,
.tg-sort-asc:hover:after,
.tg-sort-desc:after {
  visibility: visible;
  opacity: 0.4;
}
.tg-sort-desc:after {
  border-bottom: none;
  border-width: 5px 5px 0;
}

@media screen and (max-width: 767px) {
  .tg {
    width: auto !important;
  }
  .tg col {
    width: auto !important;
  }
  .tg-wrap {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.rt {
  text-align: right !important;
}
.ctr {
  text-align: center !important;
}
.lft {
  text-align: left !important;
}
.tg-0lax i {
  font-size: 14px;
}

.expd {
  height: calc(100vh - 90px) !important;
  max-height: calc(100vh - 90px) !important;
}

/*stick first column*/

/*.full-width .tg-wrap tr {position: relative;}*/
.full-width .tg td:first-child,
.full-width thead tr th:first-child {
  background-color: #fff;
  left: 0px;
  z-index: 3;
  position: sticky;
  box-shadow: inset -2px 0px 1px -1px rgba(0, 0, 0, 0.1);
}
.full-width thead tr th:first-child {
  z-index: 4;
  background-color: #fbfbfb;
  box-shadow: inset -2px -2px 1px -1px rgba(0, 0, 0, 0.1) !important;
}

.full-width .tg tr:nth-child(even) td:first-child {
  background-color: #f9f9f9; /* Alternate color for even rows */
}

.full-width .tg tr:nth-child(odd) td:first-child {
  background-color: #fff; /* Default color for odd rows */
}

.full-width .tg tr.rowhover td:first-child {
  background-color: #f0f1f5 !important;
}
.tgs-status {
  background-color: transparent;
  padding: 2px 9px 2px 7px;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  display: inline-block;
  line-height: 1.2em;
  white-space: nowrap;
}

.tgs-status::before {
  background-color: #eee;
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.tgs-live.tgs-status::before {
  background-color: #64ba45;
}
.tgs-pending.tgs-status::before {
  background-color: #f09c51;
}
.tgs-submitted.tgs-status::before {
  background-color: #f09c51;
}
.tgs-paused.tgs-status::before {
  background-color: #e6c336;
}
.tgs-ended.tgs-status::before {
  background-color: #81a1c2;
}
.tgs-canceled.tgs-status::before {
  background-color: #fd9807;
}
::v-deep .rowhover {
  background-color: #f0f1f5 !important;
}
.loading {
  pointer-events: none;
  opacity: 0.5;
}
::v-deep .btn-size2 {
  margin: 0px 7px 0px 10px;
  padding: 7px 12px 8px;
  border-radius: 6px;
}
.light-theme {
  tr {
    border-bottom: none !important;
  }
}
.spinner {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
