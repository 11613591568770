<template>
  <div>
    <div class="pnl_itm_title">
      <div class="pnl_itm_title_txt active">Daily View</div>
      <div class="pnl_title_right">
        <div class="pnl_menu_btn" @click="handleExportClick">
          <font-awesome-icon :icon="['fas', 'arrow-down-to-line']" />
        </div>
      </div>
    </div>
    <div class="sm_pnl_subtop_wrap">
      <div class="sm_pnl_subtop sbt_left" style="width:220px">
        <b-dropdown-flat
          :disabled="loading"
          :options="metricOptions"
          component-id="dailyViewOptions"
          :allow-multi-select="true"
          check-box-position="left"
          :show-all="false"
          :hide-selections="true"
          @selected-options="onSelectMetric"
        />
      </div>
    </div>
    <div class="line-chart-container">
      <line-chart
        v-if="!loading && columns.length"
        :columns="columns"
        :height="400"
        :brand-color="brandColor"
      />
      <div v-else-if="!loading && !columns.length" class="txt-center">
        No data is available to show
      </div>
    </div>
    <b-loading-spinner v-if="loading" class="txt-center" />
  </div>
</template>
<script>
import { get } from 'vuex-pathify';
import LineChart from './charts/line-chart.vue';
import BDropdownFlat from './elements/b-dropdown-flat.vue';
import BLoadingSpinner from './elements/b-loading-spinner.vue';
import defaultTheme from '~/plugins/Theme/defaultTheme';
import { DEFAULT_BRAND_COLOR } from '~/constant';

export default {
  components: {
    BLoadingSpinner,
    LineChart,
    BDropdownFlat,
  },
  props: {
    metricOptions: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      brandColor: DEFAULT_BRAND_COLOR,
    };
  },
  computed: {
    account: get('common/account'),
  },
  watch: {
    account(account) {
      if (account?.organization?.whitelabelEnabled) {
        this.brandColor = account?.organization?.theme?.primaryColor
          ? account?.organization?.theme?.primaryColor
          : defaultTheme.primaryColor;
      } else {
        this.brandColor = defaultTheme.primaryColor;
      }
    },
  },
  methods: {
    handleExportClick() {
      const header = [];
      const lines = [];
      header.push('Date');
      const { currentDays } = this.columns[0];
      currentDays.forEach((day) => {
        lines.push([day]);
      });

      this.columns.forEach((row) => {
        const { exportRaw } = row;
        const columnsToRead = [];
        exportRaw.forEach((entry, i) => {
          const { data } = entry[1];
          if (i === 0) {
            entry[0].data.forEach((col, j) => {
              if (!header.includes(col)) {
                header.push(col);
                columnsToRead.push(j);
              }
            });
          }

          data.forEach((col, k) => {
            if (columnsToRead.includes(k)) {
              lines[i].push(col);
            }
          });
        });
      });

      const csvData = [header, ...lines];
      const fileName = 'export_daily_view.csv';
      this.$emit('on-export', fileName, csvData);
    },
    // this function is being used to get the array of current selection of daily view options
    // opts: array of selected options i.e. [{checked: true, value: 'Impressions'}, {checked: true,value:'Spend'}] etc.
    onSelectMetric(options = []) {
      const selectedMetrics = options.filter((o) => o.checked).map((o) => o.id);
      this.$emit('on-options-change', selectedMetrics);
    },
    /*
    onSelectMetric(options) {
      this.selectedMetrics = options.filter((o) => o.checked).map((o) => o.id);
      this.$emit('on-options-change', this.selectedMetrics);
    },
    */
  },
};
</script>
<style lang="scss" scoped>
.txt-center {
  text-align: center;
}
.pnl_itm_title {
  border-bottom: 1px solid #e0e5ee;
  padding-bottom: 0px;
  white-space: nowrap;
}

.pnl_itm_title_txt {
  display: inline-block;
  position: relative;
  border-bottom: 0px solid;
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  color: var(--primarylight);
  font-size: 1.02em;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
.pnl_itm_title_txt:hover {
  color: var(--primarydark);
}
.pnl_itm_title_txt.active {
  display: inline-block;
  position: relative;
  border-bottom: 3px solid var(--primarycolor);
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  font-weight: 600;
  color: var(--primarydark);
}

/*small panel under title dropdown*/
.sm_pnl_subtop {
  margin: 0px;
  vertical-align: top;
}

.sm_pnl_subtop_wrap {
  border-bottom: 1px solid #e4e6ec;
  margin-bottom: 0px;
  white-space: nowrap;
}

::v-deep .sm_pnl_subtop .dropdown {
  width: 100%;
  max-width: 100%;
  min-width: auto;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  vertical-align: bottom;
  font-size: 0.93em;
}
::v-deep .sm_pnl_subtop .dropdown .select {
  padding: 9px 20px 9px 1px;
}
::v-deep .sm_pnl_subtop .dropdown .select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

::v-deep .sm_pnl_subtop .dropdown:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

::v-deep .sm_pnl_subtop .droparw {
  right: inherit;
  margin-left: 8px;
}

.sbt_right {
  display: inline-block;
  width: 49%;
}
.sbt_left {
  display: inline-block;
  width: 49%;
}
::v-deep .sm_pnl_subtop .dropdown.rt .select {
  padding: 9px 30px 9px 10px;
}
::v-deep .dropdown.rt .dropdown-menu li,
::v-deep .dropdown.rt .dropdown-menu-multi li {
  padding: 5px 20px 5px 10px;
}

::v-deep .sbt_left .droparw {
  right: inherit;
  margin-left: 8px;
}

::v-deep .sbt_left .top-span {
  margin-right: 10px;
}
</style>
<style lang="scss">
.line-chart-container {
  margin-bottom: 20px;
}

.pnl_title_right {
  display: inline-block;
  width: 250px;
  text-align: right;
  float: right;
  margin-top: -6px;
  position: relative;
}
.one-thirds .pnl_title_right {
  width: 30px;
}

.pnl_menu_btn {
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding: 7px;
  margin-right: -7px;
  font-size: 16px;
  color: var(--primarylight);
}
.pnl_menu_btn:hover {
  color: var(--primarycolor);
}
</style>
