var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pnl_itm_title"},[_c('div',{staticClass:"pnl_itm_title_txt active"},[_vm._v("Top Performers")]),_c('div',{staticClass:"pnl_title_right"},[_c('div',{staticClass:"pnl_menu_btn",on:{"click":_vm.handleExportClick}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-down-to-line']}})],1)])]),(!_vm.loading)?_c('div',{staticClass:"panel_scroll_wrap",staticStyle:{"height":"393px"}},[_c('div',{staticClass:"tg-wrap"},[_c('table',{staticClass:"tg hm_table",attrs:{"id":"tg-wqhPh"}},[_c('thead',[_c('tr',[_c('div',{staticClass:"sm_pnl_subtop_wrap"},[(!_vm.hasMultiLevelDimensions)?_c('div',{staticClass:"sm_pnl_subtop sbt_left",staticStyle:{"width":"60%"}},[_c('b-dropdown-flat',{attrs:{"options":_vm.dimensionOptions,"show-option-with-icon":true,"allow-multi-select":true,"show-all":false,"component-id":"dimensionOptionsDropdown"},on:{"selected-options":_vm.onSelectDimension}})],1):_vm._e(),(_vm.hasMultiLevelDimensions)?_c('div',{staticClass:"sm_pnl_subtop sbt_left",staticStyle:{"width":"60%"}},[_c('b-dropdown-multilevel',{attrs:{"options":_vm.dimensionOptions,"allow-multi-select":true,"show-all":false,"component-id":"dimensionOptionsDropdown"},on:{"selected-options":_vm.onSelectDimension}})],1):_vm._e(),_c('div',{staticClass:"sm_pnl_subtop sbt_right",staticStyle:{"width":"38%"}},[_c('b-dropdown-flat',{key:JSON.stringify(_vm.metricOptions),attrs:{"options":_vm.metricOptions,"component-id":"metricOptionsDropdown"},on:{"on-change":_vm.onSelectMetric}})],1)])])]),(!_vm.loading && _vm.rows.length)?_c('tbody',{ref:"table-scroll-top-perform",staticClass:"top-performance-tbody table-scroll-top-perform",attrs:{"id":_vm.tooltipId}},_vm._l((_vm.sortedRows),function(row,i){return _c('tr',{key:i,style:({ position: 'relative' })},[_c('td',{staticClass:"tg-0lax tg-type",class:{
                'tg-sort-header': i === 0,
                'tg-sort-asc': i === 0 && _vm.iconSortDirection === 'desc',
                'tg-sort-desc': i === 0 && _vm.iconSortDirection === 'asc',
              },style:({ width: '67px' }),on:{"click":function($event){i === 0 ? _vm.iconClickHandler() : null}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', row.icon]}})],1),_c('td',{ref:`${i}_metadata`,refInFor:true,staticClass:"tg-0lax tg-txt",class:{
                'tg-sort-header': i === 0,
                'tg-sort-asc': i === 0 && _vm.sortBy === 'name' && _vm.sortDirection === 'desc',
                'tg-sort-desc': i === 0 && _vm.sortBy === 'name' && _vm.sortDirection === 'asc',
              },on:{"click":function($event){i === 0 ? _vm.clickHandler('name') : null},"mouseover":(el) => _vm.handleMouseOver(el, row.name, `${i}_metadata`),"mouseleave":_vm.handleMouseLeave}},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('td',{staticClass:"tg-0lax tg-val rt",class:{
                'tg-sort-header': i === 0,
                'tg-sort-asc': i === 0 && _vm.sortBy === 'value' && _vm.sortDirection === 'desc',
                'tg-sort-desc': i === 0 && _vm.sortBy === 'value' && _vm.sortDirection === 'asc',
              },style:({ width: '114px' }),on:{"click":function($event){i === 0 ? _vm.clickHandler('value') : null}}},[_vm._v(" "+_vm._s(_vm.formatNumber(row.value, _vm.formatType, _vm.decimals))+" ")])])}),0):_vm._e(),(!_vm.loading && !_vm.rows.length)?_c('tbody',{ref:"table-scroll-top-perform",staticClass:"top-performance-tbody table-scroll-top-perform"},[_vm._m(0)]):_vm._e()])]),_c('b-tooltip',{attrs:{"tooltip-text":_vm.activeEl.text,"visible-style":{ ..._vm.activeEl.style, zIndex: 9999 }}})],1):_vm._e(),_c('div',{staticClass:"spinner"},[(_vm.loading)?_c('b-loading-spinner',{staticClass:"txt-center"}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"tg-0lax tg-txt lft"},[_vm._v(" No data is available to show ")])])
}]

export { render, staticRenderFns }