<template>
  <div :style="visibleStyle" class="text_tooltip">
    {{ tooltipText }}
  </div>
</template>

<script>
export default {
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
    visibleStyle: {
      type: Object,
      required: false,
      default: () => {},
    },
    maxLength: {
      type: Number,
      required: false,
      default: () => 34,
    },
  },
};
</script>

<style lang="scss">
.box_tooltip {
  &:hover .text_tooltip {
    opacity: 0.96;
    visibility: visible;
  }
}
.text_tooltip {
  opacity: 0;
  position: absolute;
  background-color: #1a1b1d;
  border-radius: 4px;
  box-shadow: none;
  z-index: 2;
  text-align: left;
  font-size: 11px;
  padding: 3px 6px;
  color: #999;
  -webkit-transition: opacity 0.6s ease-in-out;
  -moz-transition: opacity 0.6s ease-in-out;
  -ms-transition: opacity 0.6s ease-in-out;
  -o-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;
  visibility: visible;
  // max-width: 1000px; /* Adjust the max-width as needed */
  // max-width: calc(100vw - 10vw);
  width: max-content;
}
</style>
