var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tb-expand"},[_c('span',{staticClass:"expd-open",on:{"click":_vm.handleTableExpand}},[_c('font-awesome-icon',{staticClass:"exd-icon",attrs:{"icon":[
          'far',
          `${
            _vm.isTableExpanded
              ? 'arrow-down-left-and-arrow-up-right-to-center'
              : 'arrow-up-right-and-arrow-down-left-from-center'
          }`,
        ]}})],1)]),_c('div',{staticClass:"pnl_title_right"},[_c('div',{staticClass:"pnl_top_icon_wrap"},[_c('div',{staticClass:"pnl_top_icon",on:{"click":_vm.handleExportClick}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-down-to-line']}})],1)])]),_c('div',{staticClass:"pnl_itm_title"},[(!_vm.hasTabs)?_c('div',{staticClass:"pnl_itm_title_txt active"},[_vm._v(_vm._s(_vm.sectionTitle))]):_vm._l((_vm.tabOptions),function(tab,i){return _c('div',{key:i,staticClass:"pnl_itm_title_txt",class:{ active: tab.id === _vm.selectedTab },on:{"click":function($event){return _vm.onTabClick(tab)}}},[_vm._v(" "+_vm._s(tab.value)+" ")])})],2),_c('div',{ref:"table-scroll",staticClass:"panel_scroll_wrap table-scroll",class:_vm.isTableExpanded ? 'reactive-ht' : 'height270',staticStyle:{"overflow-x":"scroll"}},[(!_vm.loading && _vm.rows.length)?_c('div',{staticClass:"tg-wrap"},[_c('table',{staticClass:"tg hm_table",attrs:{"id":"tg-wqhPh"}},[_c('thead',[_c('tr',_vm._l((_vm.newMetaData),function(col,j){return _c('th',{key:j,ref:`${j}_newMetaData`,refInFor:true,staticClass:"tg-ul38 tg-sort-header th-title-tt",class:{
                firstCol: j === 0,
                firstColExpanded: j === 0 && _vm.isTableExpanded,
                rt: col.align === 'right',
                ctr: col.align === 'center',
                'tg-sort-asc': _vm.sortBy === col.key && _vm.sortDirection === 'desc',
                'tg-sort-desc': _vm.sortBy === col.key && _vm.sortDirection === 'asc',
              },on:{"click":(e) => _vm.clickHandler(e, col),"mouseover":(e) => _vm.handleMouseOver(e, col.value, `${j}_newMetaData`, true, true),"mouseleave":_vm.handleMouseLeave}},[_vm._v(" "+_vm._s(col.value)+" "),(col.value === 'Pacing')?_c('div',{staticClass:"pacingHeader"},[_c('div',[_c('i',{ref:"pacingTooltipIcon",refInFor:true,staticClass:"fa-solid fa-circle-info",attrs:{"aria-hidden":"true"}})])]):_vm._e()])}),0)]),_c('tbody',_vm._l((_vm.sortedRows),function(row,i){return _c('tr',{key:i,class:{ rowhover: i === _vm.hoveredRowIndex },style:({ position: 'relative' }),attrs:{"id":i},on:{"mouseover":function($event){return _vm.handleMouseOverRow(i)},"mouseleave":_vm.handleMouseLeaveRow}},_vm._l((_vm.newMetaData),function(col,j){return _c('td',{key:j,ref:`${i}_${j}_newMetaData`,refInFor:true,staticClass:"tg-0lax",class:{
                firstCol: j === 0,
                firstColExpanded: j === 0 && _vm.isTableExpanded,
                rt: col.align === 'right',
              },on:{"mouseover":(el) =>
                  _vm.handleMouseOver(
                    el,
                    _vm.formattedColumnValue(row, col),
                    `${i}_${j}_newMetaData`,
                    j === 0
                  ),"mouseleave":_vm.handleMouseLeave}},[(col.formatter && col.formatter.type === 'pacing')?_c('pacing-chart',{key:row[col.key],attrs:{"pacing":Math.round(Number(row[col.key])),"row-data":row}}):(col.formatter && col.formatter.type === 'status')?_c('div',{key:row[col.key],staticClass:"tgs-status",class:`tgs-${row[col.key]?.toLowerCase()}`},[_vm._v(" "+_vm._s(row[col.key])+" ")]):[_vm._v(" "+_vm._s(_vm.formattedColumnValue(row, col))+" ")]],2)}),0)}),0)])]):_vm._e(),_c('b-tooltip',{attrs:{"tooltip-text":`${_vm.activeEl.text}`,"visible-style":{ ..._vm.activeEl.style, zIndex: 9999 }}}),(!_vm.loading && !_vm.rows.length)?_c('div',{staticClass:"txt-center",staticStyle:{"margin-top":"13px","font-size":"14px !important"}},[_vm._v(" No data is available to show ")]):_vm._e(),_c('div',{staticClass:"spinner"},[(_vm.loading)?_c('b-loading-spinner',{staticClass:"txt-center"}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }