/**
 * performance-brand-constant.js
 *
 * This file includes constant values required for Performance Brand solution.
 * This may include enums, reference IDs or data. For example:
 *
 * - data like the list of emails to show in various dropdowns
 * - account IDs for enabling specific functionality
 */
export const DISTRIBUTION_DIMENSION_OPTIONS = [
  { id: 'campaign', value: 'Campaign', headerTitle: 'Campaign' },
  { id: 'adgroup', value: 'Ad Group', headerTitle: 'Ad Group' },
  { id: 'mediatype', value: 'Media Type', headerTitle: 'Media Type' },
  { id: 'creative', value: 'Creatives', headerTitle: 'Creatives' },
  { id: 'audience', value: 'Audiences', headerTitle: 'Audiences' },
  { id: 'country', value: 'Country', headerTitle: 'Country' },
  { id: 'state', value: 'State', headerTitle: 'State' },
  { id: 'dma', value: 'DMA', headerTitle: 'DMA' },
  { id: 'device', value: 'Devices', headerTitle: 'Devices' },
];

export const ACCOUNT_SPECIFIC_DISTRIBUTION_DIMENSION_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Contract ID', headerTitle: 'Contract ID' },
    { id: 'adgroup', value: 'ConLine ID', headerTitle: 'ConLine ID' },
    { id: 'audience', value: 'Tier', headerTitle: 'Tier' },
  ],
};

export const DISTRIBUTION_MEASUREMENT_OPTIONS = [
  { id: 'impressions', value: 'IMPs', selected: true },
  { id: 'spend', value: 'Spend', selected: false },
  { id: 'clicks', value: 'Clicks', selected: false },
  { id: 'completions', value: 'Completed Views', selected: false },
  { id: 'conversions', value: 'Conversions', selected: false },
  { id: 'revenue', value: 'Revenue', selected: false },
];

export const DAILY_VIEW_METRIC_OPTIONS = [
  { id: 'impressions', value: 'Impressions', legendColor: '#00abb7', checked: true },
  { id: 'spend', value: 'Spend', legendColor: '#e23d56', checked: false },
  { id: 'clicks', value: 'Clicks', legendColor: '#ff8f3a', checked: false },
  { id: 'ctr', value: 'CTR', legendColor: '#ffd151', checked: false },
  { id: 'completions', value: 'Completed Views', legendColor: '#d4e885', checked: false },
  { id: 'vcr', value: 'VCR', legendColor: '#3eaa73', checked: false },
  { id: 'conversions', value: 'Conversions', legendColor: '#775ec7', checked: false },
];

export const ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS = [
  { id: 'campaign', value: 'Active Campaigns' },
  { id: 'adgroup', value: 'Active Ad Groups', selected: true },
];

export const ACCOUNT_SPECIFIC_ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Active Contract ID' },
    { id: 'adgroup', value: 'Active ConLine ID', selected: true },
  ],
};

export const TOP_PERFORMANCE_DIMENTIONS = [
  { id: 'creative', value: 'Creative', icon: 'rectangle-ad', checked: true },
  { id: 'audience', value: 'Audience', icon: 'users', checked: true },
];

export const ACCOUNT_SPECIFIC_TOP_PERFORMANCE_DIMENTIONS = {
  73: [{ id: 'audience', value: 'Tier', icon: 'users', checked: true }],
};

export const CAMPAIGN_SUB_TAB_OPTIONS = [
  { id: 'campaign', value: 'Campaigns' },
  { id: 'adgroup', value: 'Ad Groups', selected: true },
];

export const ACCOUNT_SPECIFIC_CAMPAIGN_SUB_TAB_OPTIONS = {
  73: [
    { id: 'campaign', value: 'Contract ID' },
    { id: 'adgroup', value: 'ConLine ID', selected: true },
  ],
};
export const GEO_SUB_TAB_OPTIONS = [
  { id: 'country', value: 'Country' },
  { id: 'state', value: 'State', selected: true },
  { id: 'dma', value: 'DMA' },
];

export const DELIVERY_TAB_OPTIONS = [
  {
    id: 'campaign_adgroup',
    value: 'Campaigns & Ad Groups',
    subTabs: ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS,
    selected: true,
  },
  { id: 'mediatype', value: 'Media Types' },
  { id: 'creative', value: 'Creatives' },
  { id: 'audience', value: 'Audiences' },
  { id: 'geo', value: 'Geo', subTabs: GEO_SUB_TAB_OPTIONS },
  { id: 'device', value: 'Devices' },
  {
    id: 'top-domains',
    value: 'Top Domains',
    viewUrl: '/views/VIAMedia-TopDomainsReport/CustomReport',
  },
];

export const ACCOUNT_SPECIFIC_DELIVERY_TAB_OPTIONS = {
  73: [
    {
      id: 'campaign_adgroup',
      value: 'Contract ID & ConLine ID',
      subTabs: ACCOUNT_SPECIFIC_ACTIVE_CAMPAIGN_SUB_TAB_OPTIONS[73],
      selected: true,
    },
    { id: 'audience', value: 'Tier' },
    {
      id: 'publishers',
      value: 'Publishers',
      viewUrl: '/views/VIAMedia-TopPublishersReport/CustomReport',
    },
    {
      id: 'geofence',
      value: 'Geofence',
      viewUrl: '/views/VIAMedia-GeofenceReport/CustomReport',
    },
  ],
};

export const DELIVERY_METRIC_OPTIONS = [
  { id: 'ctr', value: 'CTR' },
  { id: 'vcr', value: 'VCR' },
  { id: 'cpc', value: 'CPC' },
  { id: 'cpcv', value: 'CPCV' },
  { id: 'roas', value: 'ROAS' },
];

export const PERFORMANCE_ANALYSIS_DIMENSION_COLUMN = {
  key: 'name',
  value: 'Campaign Name',
  align: 'left',
};

export const PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS = [
  {
    key: 'startDate',
    value: 'Start',
    align: 'left',
    formatter: { type: 'datetime', format: 'M/D/YYYY' },
  },
  {
    key: 'endDate',
    value: 'End',
    align: 'left',
    formatter: { type: 'datetime', format: 'M/D/YYYY' },
  },
  {
    key: 'budget',
    value: 'Budget',
    align: 'right',
    formatter: { type: 'number', format: '$0,0.[00]' },
  },
  {
    key: 'status',
    value: 'Status',
    align: 'left',
    formatter: { type: 'status' },
  },
  {
    key: 'actualPacing',
    value: 'Pacing',
    align: 'center',
    formatter: { type: 'pacing', format: '0%' },
  },
];
export const PERFORMANCE_ANALYSIS_COLUMNS_AD_UNIT = [
  {
    key: 'keyMediaTypeFilter',
    value: 'Ad Unit',
    align: 'right',
    formatter: { type: 'string', format: '' },
  },
];
export const PERFORMANCE_ANALYSIS_COLUMNS = [
  {
    key: 'spend',
    value: 'Spend',
    align: 'right',
    formatter: { type: 'number', format: '$0,0.[00]' },
  },
  {
    key: 'impressions',
    value: 'IMPs',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'clicks',
    value: 'Clicks',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'ctr',
    infinityKey: 'ctrInfinity',
    value: 'CTR',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[00]%' },
  },
  {
    key: 'completions',
    value: 'Views',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'vcr',
    infinityKey: 'vcrInfinity',
    value: 'VCR',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[0]%' },
  },
];

export const PERFORMANCE_ANALYSIS_CONVERSIONS_COLUMNS = [
  {
    key: 'conversions',
    value: 'Conversions',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'cpa',
    infinityKey: 'cpaInfinity',
    value: 'CPA',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
];
export const PERFORMANCE_ANALYSIS_REVENUES_COLUMNS = [
  {
    key: 'revenue',
    value: 'Revenue',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'roas',
    infinityKey: 'roasInfinity',
    value: 'ROAS',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[0]%' },
  },
];

export const PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION = {
  campaign: [...PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS, ...PERFORMANCE_ANALYSIS_COLUMNS],
  adgroup: [
    ...PERFORMANCE_ANALYSIS_COLUMNS_AD_UNIT,
    ...PERFORMANCE_ANALYSIS_CAMPAIGN_COLUMNS,
    ...PERFORMANCE_ANALYSIS_COLUMNS,
  ],
  other: [...PERFORMANCE_ANALYSIS_COLUMNS],
};

export const PERFORMANCE_ANALYSIS_DIMENSION_TITLES = {
  campaign: 'Campaign Name',
  adgroup: 'Ad Group Name',
  mediatype: 'Media Type',
  creative: 'Creative',
  audience: 'Audience',
  country: 'Country',
  state: 'State',
  dma: 'DMA',
  device: 'Device',
};

export const ACCOUNT_SPECIFIC_PERFORMANCE_ANALYSIS_DIMENSION_TITLES = {
  73: {
    campaign: 'Contract ID Name',
    adgroup: 'ConLine ID Name',
    audience: 'Tier',
  },
};

export const REPORT_DIMENSION_OPTIONS = [
  { id: 'overall', value: 'Overall' },
  { id: 'day', value: 'Day' },
  { id: 'campaign', value: 'Campaign', headerTitle: 'Campaign' },
  { id: 'adgroup', value: 'Ad Group', headerTitle: 'Ad Group' },
  { id: 'mediatype', value: 'Media Type', headerTitle: 'Media Type' },
  { id: 'creative', value: 'Creatives', headerTitle: 'Creatives' },
  { id: 'audience', value: 'Audiences', headerTitle: 'Audiences' },
  { id: 'country', value: 'Country', headerTitle: 'Country' },
  { id: 'state', value: 'State', headerTitle: 'State' },
  { id: 'zip', value: 'Zip Code', headerTitle: 'Zip Code' },
  { id: 'dma', value: 'DMA', headerTitle: 'DMA' },
  { id: 'device', value: 'Devices', headerTitle: 'Devices' },
];

export const ACCOUNT_SPECIFIC_REPORT_DIMENSION_OPTIONS = {
  73: [...ACCOUNT_SPECIFIC_DISTRIBUTION_DIMENSION_OPTIONS[73]],
};

export const REPORT_DIMENSION_COLUMN = {
  ...PERFORMANCE_ANALYSIS_DIMENSION_COLUMN,
};
export const REPORT_METRIC_OPTIONS = [
  ...PERFORMANCE_ANALYSIS_COLUMNS,
  {
    key: 'cpm',
    infinityKey: 'cpmInfinity',
    value: 'CPM',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'cpc',
    infinityKey: 'cpcInfinity',
    value: 'CPC',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'cpcv',
    infinityKey: 'cpcvInfinity',
    value: 'CPCV',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
];

export const REPORT_DIMENSION_TITLES = {
  overall: 'Overall',
  day: 'Day',
  campaign: 'Campaign Name',
  adgroup: 'Ad Group Name',
  mediatype: 'Media Type',
  creative: 'Creative',
  audience: 'Audience',
  country: 'Country',
  state: 'State',
  dma: 'DMA',
  zipcode: 'Zip Code',
  device: 'Device',
};

export const ACCOUNT_SPECIFIC_REPORT_DIMENSION_TITLES = {
  73: {
    campaign: 'Contract ID Name',
    adgroup: 'ConLine ID Name',
    audience: 'Tier',
  },
};
