/**
 * converged-tv-constant.js
 *
 * This file includes constant values required for Converged TV solution.
 * This may include enums, reference IDs or data. For example:
 *
 * - data like the list of emails to show in various dropdowns
 * - account IDs for enabling specific functionality
 */
export const OVERVIEW_TAB_OPTIONS = [
  {
    id: 'overview',
    value: 'Overview',
    viewUrl: '/views/TVInsights-Overview/Overview',
    demoViewUrl: '/views/DemoTVInsights-Overview/Overview',
    selected: true,
  },
  {
    id: 'analysis',
    value: 'Competitor Analysis',
    viewUrl: '/views/TVInsights-CompetitorAnalysis/CompetitorAnalysis',
    demoViewUrl: '/views/DemoTVInsights-CompetitorAnalysis/CompetitorAnalysis',
  },
  {
    id: 'planner',
    value: 'Planner',
    viewUrl: '/views/TVInsights-Planner/Planner',
    demoViewUrl: '/views/DemoTVInsights-Planner/Planner',
  },
];

export const DELIVERY_TAB_OPTIONS = [
  {
    id: 'delivery',
    value: 'Delivery',
    viewUrl: '/views/TVInsights-Delivery/Delivery',
    demoViewUrl: '/views/DemoTVInsights-Delivery/Delivery',
    selected: true,
  },
];

export const PERFORMANCE_TAB_OPTIONS = [
  {
    id: 'conversions',
    value: 'Conversions',
    viewUrl: '/views/TVInsights-Conversions/Conversions',
    demoViewUrl: '/views/DemoTVInsights-Conversions/Conversions',
    selected: true,
  },
  {
    id: 'measurement',
    value: 'Cross-Platform Measurement',
    viewUrl: '/views/TVInsights-Cross-PlatformMeasurement/Cross-PlatformMeasurement',
    demoViewUrl: '/views/DemoTVInsights-Cross-PlatformMeasurement/Cross-PlatformMeasurement',
  },
];
