<template>
  <div
    ref="aExportDropdown"
    class="performance-export-popup popup"
    :style="{ top: top, left: left }"
  >
    <div class="dropdown-container">
      <div
        v-for="(item, i) in menuItems"
        :key="i"
        class="dropdown-option"
        @click="onClickType(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AExportDropdown',

  components: {},
  props: {
    top: { required: true, type: String, default: '' },
    left: { required: true, type: String, default: '' },
    menuItems: { required: true, type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  computed: {},

  mounted() {
    setTimeout(() => {
      window.addEventListener('click', this.onClickOutsideEvent);
      window.addEventListener('keydown', this.onClickOutsideEventKey);
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutsideEvent);
    window.removeEventListener('keydown', this.onClickOutsideEventKey);
  },

  methods: {
    onClickType(item) {
      this.$emit('type-selected', item.type);
    },
    close() {
      this.$emit('close');
    },

    // events
    onClickOutsideEvent(event) {
      const el = this.$refs.aExportDropdown;
      if (el && !(el === event.target || el.contains(event.target))) {
        this.close();
      }
    },
    exportDownload() {
      this.$emit('download');
      this.$emit('close');
    },
    handleTableRowClick() {
      this.$emit('tableRowClick');
      this.$emit('close');
    },

    onClickOutsideEventKey(event) {
      if (event.key === 'Escape') {
        if (!(this.$el === event.target || this.$el.contains(event.target))) {
          this.close();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.performance-export-popup {
  background: #f9f9f9;
  position: absolute;
  z-index: 9;
  max-height: 250px;
  margin-top: 0;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 0 1px 4px 4px;
  box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
  border: none;
  .dropdown-container {
    background-color: #f9f9f9;
  }
  .dropdown-option {
    padding: 10px 17px;
    font-size: 14px;
    color: #555;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
</style>
