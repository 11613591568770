<template>
  <div v-if="tableauJwtToken" class="bdc_main_wrap whero">
    <tableau-viz id="tableauViz" :src="tableauURL" :token="tableauJwtToken" toolbar="top">
      <!-- <viz-filter field="Advertiser ID" :value="advertiserId"></viz-filter>
      <viz-filter field="Start Date" :value="startDate"></viz-filter>
      <viz-filter field="End Date" :value="endDate"></viz-filter>
      <viz-filter field="Client ID" :value="accountId"></viz-filter>
      <viz-parameter field="embed" value="yes"></viz-parameter> -->
      <custom-parameter name=":showShareOptions" value="false"></custom-parameter>
    </tableau-viz>
  </div>
</template>
<script>
import { formatDateForAPI } from '@/util/apiDateFormat';
import _ from 'underscore';
import axios from 'axios';
import { get } from 'vuex-pathify';
import config from '~/config';

export default {
  name: 'ReportInfoContainer',
  props: {
    reportInfo: {
      required: false,
      type: Object,
      default: () => {},
    },
    accountLevel: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableauJwtToken: null,
      tableauURL: this.reportInfo.url,
      // tableauURL: 'https://dev-tableau.digitalremedy.com/views/CountsbyDMAName/Sheet1',
    };
  },

  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    dates: get('dashboard/dates'),
    advertiserId() {
      return this.advertiser ? this.advertiser.id : 0;
    },
    accountId() {
      return this.account ? this.account.id : 0;
    },
    startDate() {
      return formatDateForAPI(this.dates.startDate);
    },
    endDate() {
      return formatDateForAPI(this.dates.endDate);
    },
  },

  watch: {
    advertiserId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },

    accountId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    startDate: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },

    endDate: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
  },

  async mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      `${config.TABLEAU_URL}/javascripts/api/tableau.embedding.3.latest.js`
    );
    recaptchaScript.setAttribute('type', 'module');
    document.head.appendChild(recaptchaScript);
    this.getTableauAuthToken();
  },
  methods: {
    debounceTableauAuthToken() {
      _.debounce(this.getTableauAuthToken(), 100, true);
    },
    async getTableauAuthToken() {
      const url = this.accountLevel
        ? `${config.ADREADY_URL}/api/token/tableau/account/${this.accountId}`
        : `${config.ADREADY_URL}/api/token/tableau/${this.advertiserId}`;

      axios.get(url, { withCredentials: true }).then((d) => {
        this.tableauJwtToken = d.data.result;
      });
    },
  },
};
</script>

<style lang="scss">
.def-box {
  padding: 20px 4px 10px 25px;
  max-height: 354px;
  z-index: 10;
  position: relative;
  min-height: 150px;
}
.expan {
  &.expd {
    height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
  }
}
</style>
<style lang="scss" scoped></style>
